import React from 'react';
//https://www.npmjs.com/package/react-html-parser
import ReactHtmlParser from 'react-html-parser';

// my helpers
import { pageRootUpdate } from '../../helpers/api_routes';
import Breadcrumbs from '../breadcrumbs';
import LazyLoad from 'react-lazyload';

const ArticleNews = props => {
	const item = props.item;

	return (
		<div className='layout--main'>
			<Breadcrumbs
				breadcrumbs_route={'/news-items'}
				breadcrumbs_text={'News'}
				breadcrumbs_page={item.title}
			/>
			<article className='article article--news'>
				<header className='article__header' data-aos='fade-in'>
					<LazyLoad>
						<img src={`${pageRootUpdate}${item.image}`} alt={item.title} />
					</LazyLoad>
				</header>

				<div className='grid grid--center'>
					<div className='cell cell--12--large'>
						<div className='article__body article__body--news'>
							<div className='article__main' data-aos='fade-in'>
								<h1>{item.title}</h1>
								{/*<p> {item.description} </p>*/}
								<div className='article__content font--medium gray'>
									{ReactHtmlParser(item.content)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</article>
		</div>
	);
};

export default ArticleNews;
