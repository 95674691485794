export const navLogo = require('../images/digital-church-platform.png');

export const defaultLogo = require('../images/default-logo.png');

export const birdImg = require('../images/photos/cta/bird.svg');
export const bellImg = require('../images/photos/cta/bell.svg');
export const handsImg = require('../images/photos/cta/hands.svg');
export const blobImg = require('../images/photos/cta/blob.svg');

// about section
//export const sectionImg = require('../images/photos/church.jpg');

export const loadingCircle = require('../images/icons/loading_circle.gif');

export const headerImg = require('../images/photos/header.jpeg');

//circles
/*
export const circle1 = require('../images/photos/circle_1.jpeg');
export const circle2 = require('../images/photos/circle_2.jpeg');
export const circle3 = require('../images/photos/circle_3.jpeg');
*/

export const safeguarding = require('../images/photos/safeguarding.jpeg');

// contacts

export const contact1 = require('../images/dummy/contact_1.jpg');
export const contact2 = require('../images/dummy/contact_2.jpg');
export const contact3 = require('../images/dummy/contact_3.jpg');

export const google_play = require('../images/icons/google-play-badge.png');
export const app_store = require('../images/icons/app-store-badge.png');

// Social Media
export const sm_facebook = require('../images/icons/SVG/sm/sm_facebook.svg');
export const sm_twitter = require('../images/icons/SVG/sm/sm_twitter.svg');
export const sm_linkedin = require('../images/icons/SVG/sm/sm_linkedin.svg');
export const sm_youtube = require('../images/icons/SVG/sm/sm_youtube.svg');
export const sm_trip_advisor = require('../images/icons/SVG/sm/sm_trip_advisor.svg');
export const sm_instagram = require('../images/icons/SVG/sm/sm_instagram.svg');

// histoy
export const hall = require('../images/photos/hall.jpg');
//export const churchyard = require('../images/photos/churchyard.jpg');

export const christening = require('../images/photos/baptism.jpg');
export const wedding = require('../images/photos/wedding.jpg');
export const adult_baptism = require('../images/photos/adult_baptism.jpg');
export const confirmation = require('../images/photos/confirmation.jpg');
export const funeral = require('../images/photos/funeral.jpg');

//export const safeguarding_team_1 = require('../images/photos/1.jpeg');
//export const safeguarding_team_2 = require('../images/photos/2.png');

export const bells = require('../images/photos/bells.jpg');
export const christ = require('../images/photos/christ.jpg');
export const organ = require('../images/photos/organ.jpg');

export const get_involved = require('../images/icons/watch_online.png');

export const church_of_england = require('../images/church-of-england.svg');
export const diocese_of_london = require('../images/diocese-logo.svg');

export const ch_publishing = require('../images/ch-publishing.jpg');

export const donationsImg = require('../images/photos/donations.png');

export const heart_icon = require('../images/icons/SVG/heart.svg');
export const heart_icon_active = require('../images/icons/SVG/heart_active.svg');

export const clock = require('../images/icons/SVG/clock.svg');

export const search_icon = require('../images/icons/SVG/search.svg');

export const profile = require('../images/icons/SVG/profile.svg');

export const eye_on = require('../images/icons/SVG/eye.svg');
export const eye_off = require('../images/icons/SVG/eye-off.svg');

export const check_circle = require('../images/icons/SVG/check-circle.svg');
export const x_circle = require('../images/icons/SVG/x-circle.svg');

export const icon_google = require('../images/icons/SVG/google.svg');
export const icon_apple = require('../images/icons/SVG/apple.svg');

export const digital_church_platform = require('../images/digital-church-platform.png');

export const cheimsford_logo = require('../images/cheimsford_logo.svg');