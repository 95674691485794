import React, { Component } from 'react';
//https://www.npmjs.com/package/react-html-parser

// my components
import ArticleNews from '../../components/articles/article_news';

// my helpers
import { newsItemsUrl, pageRootUrl } from '../../helpers/api_routes';
import Seo from '../../components/seo/seo';
import LazyLoad from 'react-lazyload';
import { ifString } from '../../helpers/if_string';
import ErrorMsg from '../../components/error';
import SectionLoader from '../../components/loaders/section_loader';

// for fetching data from server
const axios = require('axios');

class NewsItemShow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: this.props.currentUser,
			id:
				this.props.match.params && this.props.match.params.id
					? this.props.match.params.id
					: null,
			newsItem: null,
			awaitingServer: true,
			errors: null,
		};
	}

	componentDidMount() {
		// start loader
		this.setState({ awaitingServer: true });

		var that = this;
		axios
			.get(`${newsItemsUrl.show}${this.state.id}.json`)
			.then(function (response) {
				// backend should take care off pagination
				that.setState({
					newsItem: response.data.news_item.items,
					awaitingServer: false,
				});
			})
			.catch(function (error) {
				console.log(error.toString())
				that.setState({
					errors: error.toString(),
					awaitingServer: false,
				});
			});
	}


	render() {
		let newsItem = this.state.newsItem;
		if (newsItem) {
			let image = `${pageRootUrl}${newsItem.image}`;
			return (
				<React.Fragment>
					<div className='view'>
						<Seo
							pageSeo={{
								seo_title: ifString(
									newsItem.seo_title,
									newsItem.title
								),
								seo_description: ifString(
									newsItem.seo_description,
									newsItem.description
								),
								seo_image: image,
								canonical: newsItem.slug
							}}
						/>
						<div
							className={`${!this.state.awaitingServer
								? 'loader loader--loaded'
								: 'loader'
								}`}
						>
							<div className='view__body'>
								<LazyLoad height={1000}>
									<ArticleNews item={newsItem} />
								</LazyLoad>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		} else {
			if (this.state.errors) {
				return <ErrorMsg error={this.state.errors} />
			} else {
				return <SectionLoader />;
			}
		}
	}
}

export default NewsItemShow;
