import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// redux
/*
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { addCurrentUserAction } from '../../actions/current_user'
*/

// my components
import AwaitingServerWrapper from '../wrappers/awaiting_server_wrapper';

// my helpers
import { whatsOnsUrl, pageRootUpdate } from '../../helpers/api_routes';
import { t } from '../../locales/translations';

import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';

// for fetching data from server
const axios = require('axios');

const WhatsOns = (props) => {
	// eslint-disable-next-line
	const [user] = useState(props.currentUser);
	const [maxWhatIsOns] = useState(props.maxWhatIsOns);
	const [whatIsOns, setWhatIsOns] = useState([]);
	const [awaitingServerWhatIsOns, setAwaitingServerWhatIsOns] = useState(
		true
	);
	// eslint-disable-next-line no-unused-vars
	const [errorsWhatIsOns, setErrorsWhatIsOns] = useState(null);

	useEffect(() => {
		// start loader
		setAwaitingServerWhatIsOns(true);
		// get what is ons
		axios
			.get(whatsOnsUrl.index)
			.then(function (response) {
				// backend should take care off pagination
				setWhatIsOns(response.data.what_is_ons.items);
				setAwaitingServerWhatIsOns(false);
			})
			.catch(function (error) {
				setErrorsWhatIsOns(t('sorry_error'));
				setAwaitingServerWhatIsOns(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	var settings = {
		dots: false,
		infinite: false,
		speed: 220,
		slidesToShow: 3,
		arrows: true,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	function makeWhatIsOns(items) {
		if (items && items.length > 0) {
			return items.slice(0, maxWhatIsOns).map((item, index) => {
				return (
					<div
						key={item.id}
						className={`card card--compact card-padding`}
					>
						<div className='card__img'>
							<LazyLoad>
								<img
									src={`${pageRootUpdate}${item.image}`}
									alt={item.title}
								/>
							</LazyLoad>
						</div>
						<div className='card__body black'>
							<p className='card__copy'>{item.description}</p>
							<div className='card__title font--heading color-primary'>
								{item.title}
							</div>
							<div className='card__description'>
								{item.description}
							</div>
						</div>
						<div className='card__btn'>
							<Link
								to={{
									pathname: `/what-is-on/${item.slug}`
								}}
								className='btn btn--primary btn--transparent'
							>
								{t('find_out_more')}
							</Link>
						</div>
					</div>
				);
			});
		} else {
			return <React.Fragment />;
		}
	}

	return (
		<div
			className={`${
				!awaitingServerWhatIsOns ? 'loader loader--loaded' : 'loader'
			}`}
		>
			<AwaitingServerWrapper awaitingServer={awaitingServerWhatIsOns}>
				{props.slider ? (
					<div className='slider--whats-on'>
						<Slider {...settings}>
							{makeWhatIsOns(whatIsOns)}
						</Slider>
					</div>
				) : (
					<div className='grid--3--large grid--2--medium'>
						{makeWhatIsOns(whatIsOns)}
					</div>
				)}

				{/*<p> {t('what_is_on.check_all')} </p>*/}
				{props.seeMore && (
					<Link to='/what-is-ons' className='link--section'>
						{t('what_is_on.check_all')}
					</Link>
				)}
			</AwaitingServerWrapper>
		</div>
	);
};

export default WhatsOns;
