import React, { Component } from 'react';
//https://www.npmjs.com/package/react-html-parser

// redux
/*
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { addCurrentUserAction } from '../../actions/current_user'
*/

// my components
import ArticleBookings from '../../components/articles/article_bookings';

// my helpers
import { bookingsUrl, pageRootUrl } from '../../helpers/api_routes';
import Seo from '../../components/seo/seo';
import { ifString } from '../../helpers/if_string';
import ErrorMsg from '../../components/error';
import SectionLoader from '../../components/loaders/section_loader';

// for fetching data from server
const axios = require('axios');

class BookingsShow extends Component {
	constructor(props) {

		super(props);
		this.state = {
			currentUser: this.props.currentUser,
			item: null,
			id:
				this.props.match.params && this.props.match.params.id
					? this.props.match.params.id
					: null,
			awaitingServer: true,
			errors: null,
		};

	}

	componentDidMount() {
		// start loader

		this.setState({ awaitingServer: true });

		var that = this;
		axios
			.get(`${bookingsUrl.show}${this.state.id}.json`)
			.then(function (response) {
				// backend should take care off pagination
				that.setState({
					item: response.data.venue_hire.items,
					awaitingServer: false,
				});
			})
			.catch(function (error) {
				that.setState({
					errors: error.toString(),
					awaitingServer: false,
				});
			});
	}

	render() {
		if (this.state.item) {
			let image = `${pageRootUrl}${this.state.item.image}`;
			return (
				<React.Fragment>
					<Seo
						pageSeo={{
							seo_title: ifString(this.state.item.seo_title, this.state.item.title),
							seo_description: ifString(this.state.item.seo_description, this.state.item.description),
							seo_image: image,
							canonical: this.state.item.slug
						}}
					/>
					<div className='view--article'>
						<div className='view__body'>
							<ArticleBookings item={this.state.item} />
						</div>
						<p> {this.state.errors} </p>
					</div>
				</React.Fragment>
			);
		} else {
			if (this.state.errors) {
				return <ErrorMsg error={this.state.errors} />
			} else {
				return <SectionLoader />;
			}
		}
	}
}

export default BookingsShow;
