import React, { useRef, useEffect } from 'react';
import ReactModal from 'react-modal';
import { format } from 'date-fns';

//const axios = require('axios');

//TODO Bruno should remove????
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'rgba(#FFF, 0.15)'
	}
};

const CalendarModal = props => {
	const modalBody = useRef();

	// below is the same as componentDidMount and componentDidUnmount
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClickOutside = event => {
		if (modalBody.current && !modalBody.current.contains(event.target)) {
			props.handleClose();
		}
	};

	const setTimes = () => {
		if (props.item.allDay) {
			return <div>All Day Event</div>
		} else {
			return (<React.Fragment>
				{
					props.item.start && props.item.end ? (
						<span>
							{props.item.start} - {props.item.end}
						</span>
					) : (
						<span>
							{props.item.extendedProps &&
								props.item.extendedProps.startTime}{' '}
							-
							{props.item.extendedProps &&
								props.item.extendedProps.endTime}
						</span>
					)
				}
			</React.Fragment>)
		}
	}

	return (
		<div>
			<ReactModal
				className='modal--small'
				isOpen={props.isActive}
				style={customStyles}
				ariaHideApp={false}
				contentLabel='Some sinple modal'
			>
				<div id='modal-body'>
					<div ref={modalBody}>
						<div className='modal__close-handle'></div>
						<div className='modal__close' onClick={props.handleClose}>
							&#10005;
						</div>
						<h2 className='modal__title'>{props.item.title}</h2>
						<div className='date-time-info date-time-info--small'>
							<div>
								{props.item.start_date &&
									format(props.item.start_date, 'dd.MM.yyyy')}
							</div>
							<div className='bold font--medium'>
								{setTimes()}
							</div>
						</div>
						<div>{props.item.description}</div>
					</div>
				</div>
			</ReactModal>
		</div>
	);
};

export default CalendarModal;
