import React, { useState, useEffect } from 'react';
import { t } from '../../locales/translations';
import { safeguardingUrl, pageRootUrl, teamUrl, app_root } from '../../helpers/api_routes';

import ReactHtmlParser from 'react-html-parser';
import { safeguarding } from '../../helpers/image_paths';
import LazyLoad from 'react-lazyload';
import Seo from '../../components/seo/seo';

// for fetching data from server
const axios = require('axios');

const Safeguarding = (props) => {
	const [ safeguardings, setSafeguardings ] = useState([]);
	const [ priests, setPriests ] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [ error, setError ] = useState('');

	const getSafeguardings = () => {
		// get events
		axios
			.get(safeguardingUrl.show)
			.then(function(response) {
				setSafeguardings(response.data.template_page.item);
			})
			.catch(function(error) {
				setError(t('sorry_error'));
			});
	};

	const getTeam = () => {
		// get team
		axios
			.get(teamUrl.index)
			.then(function(response) {
				setPriests(response.data.team_members.items);
			})
			.catch(function(error) {
				setError(t('sorry_error'));
			});
	};

	useEffect(
		() => {
			getTeam();
			getSafeguardings();
		},
		[ props ]
	);

	if (safeguardings) {
		let officer =
			priests &&
			priests.find(
				(item) =>
          item.church_title.toLowerCase().includes('safeguarding officer') &&
					!item.church_title.toLowerCase().includes('assistant safeguarding officer')
			);
		let assistant = priests && priests.find((item) => item.church_title.toLowerCase().includes('assistant safeguarding officer'));

		return (
			<div className="view--article">
				<Seo pageSeo={{ seo_title: `Safeguarding | ${props.churchName}`, seo_image: app_root + safeguarding, seo_description: `Promoting a safer environment and culture` }} />
				<div className="view__body">
					<div className="layout--main">
						<article className="article article--safeguarding">
							<header className="article__header">
								<LazyLoad>
									<img src={safeguarding} alt={safeguardings.title} />
								</LazyLoad>
							</header>
							{/* <h1>{safeguardings.title}</h1> */}
							<div className="article__main">
								<div className="article__body article__body--wide">
									<div className="article__content gray">
										{ReactHtmlParser(safeguardings.content)}
										<div className="safeguarding-team">
											<div className="grid gray">
												<div className="cell cell--7--large cell--12--small font--medium">
													<p>
														The PCC appointed{' '}
														<strong>
															{officer && officer.first_name}{' '}
															{officer && officer.last_name}
														</strong>{' '}
														as our Church Safeguarding Officer.{' '}
                            {assistant ? (
                              <span>
														  {officer && officer.first_name} {officer && officer.last_name}{' '}
                              wll work closely with
                                <strong>
                                  {assistant.first_name}{' '}
                                  {assistant.last_name}{' '}
                                </strong>
                              .</span>) : (null) }
													</p>
												</div>

												<div className="cell cell--6--large cell--12--small">
													{officer && (
														<div className="team-member">
															<div className="team-member__img">
																<LazyLoad>
																	<img
																		src={`${pageRootUrl}/${officer.image}`}
																		alt={officer.name}
																	/>
																</LazyLoad>
															</div>
															<div className="font--medium bold margin--regular team-member__title">
																{officer.first_name} {officer.last_name}, Church
																Safeguarding Officer
															</div>
															<a href={`mailto:${officer.email}`}>
																Email: - {officer.email}
															</a>
															<div>Telephone: - {officer.tel}</div>
														</div>
													)}
												</div>

												<div className="cell cell--6--large cell--12--small">
													{assistant && (
														<div className="team-member">
															<div className="team-member__img">
																<LazyLoad>
																	<img
																		src={`${pageRootUrl}/${assistant.image}`}
																		alt={assistant.name}
																	/>
																</LazyLoad>
															</div>
															<div className="font--medium bold margin--regular team-member__title">
																{assistant.first_name} {assistant.last_name}, Assistant
																Safeguarding Officer
															</div>
															<a href={`mailto:${assistant.email}`}>
																Email: - {assistant.email}
															</a>
															<div>Telephone: - {assistant.tel}</div>
														</div>
													)}
												</div>

												<div className="cell cell--8--large">
													<div className="margin--double font--medium gray">
														<span className="bold">
															{officer && officer.first_name}{' '}
															{officer && officer.last_name}
														</span>{' '}
														is the first person to speak to if you have any concerns around
														the welfare of a child or adult with vulnerability. Copies of
														“Promoting a Safer Diocese 2019” and any parish guidelines and
														procedures are held in the Parish Office.
													</div>
												</div>
											</div>
										</div>
										{ReactHtmlParser(safeguardings.useful_contacts)}
									</div>
								</div>
							</div>
						</article>
					</div>
				</div>
			</div>
		);
	} else {
		return null
	}
};

export default Safeguarding;
