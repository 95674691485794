import React, { useRef, useEffect } from 'react';
import ReactModal from 'react-modal';
import Mailchimp from 'react-mailchimp-form';

//const axios = require('axios');

//TODO Bruno should remove????
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'rgba(#FFF, 0.15)',
	},
};

const MailModal = (props) => {
	const modalBody = useRef();

	// below is the same as componentDidMount and componentDidUnmount
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClickOutside = (event) => {
		if (modalBody.current && !modalBody.current.contains(event.target)) {
			props.handleClose();
		}
	};

	return (
		<div>
			<ReactModal
				className='modal--mail'
				isOpen={props.isActive}
				style={customStyles}
				ariaHideApp={false}
				contentLabel='Some sinple modal'
			>
				<div id='modal-body'>
					<div ref={modalBody}>
						<div className='modal__close-handle'></div>
						<div
							className='modal__close'
							onClick={props.handleClose}
						>
							&#10005;
						</div>
						<h1 className='modal__title center-inblock'>
							Receive news about Services and Events at Digital Church Platform
						</h1>

						<div className='mailchimp-form'>
							<Mailchimp
								action='https://sanctuaryinthecity.us18.list-manage.com/subscribe/post?u=9d1880e926ed74a28e1f07e49&id=32e649e05b'
								fields={[
									{
										name: 'EMAIL',
										placeholder: 'Email',
										type: 'email',
										required: true,
									},
									{
										name: 'FNAME',
										placeholder: 'First Name',
										type: 'text',
										required: false,
									},
									/* MMERGE5
									ADDRESS[addr1]
									ADDRESS[addr2]
									ADDRESS[city]
									ADDRESS[state]
									ADDRESS[zip]
									ADDRESS[country] */
									{
										name: 'LNAME',
										placeholder: 'Last Name',
										type: 'text',
										required: false,
									},
								]}
							/>
							{/* {form}  */}
						</div>
					</div>
				</div>
			</ReactModal>
		</div>
	);
};

export default MailModal;
