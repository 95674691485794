import React, { useState } from 'react';
import axios from 'axios';
import { t } from '../../locales/translations';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Notification(props) {
	const [item, setItem] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [error, setError] = useState('');

	const getNews = () => {
		axios
			.get(props.src)
			.then(function(response) {
				setItem(response.data.news_items.items);
			})
			.catch(function(error) {
				setError(t('sorry_error'));
			});
	};

	useEffect(() => {
		getNews();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (item) {
		var featured = item.find(i => i.is_featured);

		if (featured) {
			return (
				<div data-aos='fade-in'>
					<div className='layout--main'>
						<Link to={`news_item/${featured.id}`}>
							<section className='notification-wrapper'>
								<h3 className='base-font-family bold'>{featured.title}</h3>
								<p>{featured.description}</p>
							</section>
						</Link>
					</div>
				</div>
			);
		} else {
			return <React.Fragment />;
		}
	} else {
		return <React.Fragment />;
	}
}

export default Notification;
