import React, { useState, useEffect } from 'react';
import { legalsUrl } from '../../helpers/api_routes';
import { t } from '../../locales/translations';
import ArticleLegal from '../../components/articles/article_legal';
import Seo from '../../components/seo/seo';

// for fetching data from server
const axios = require('axios');

function Legals(props) {
	const [ legals, setLegals ] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [ error, setError ] = useState('');

	useEffect(
		() => {
			axios
				.get(`${legalsUrl.show}/${props.match.params.id}.json`)
				.then(function(response) {
					setLegals(response.data.template_page.item);
				})
				.catch(function(error) {
					setError(t('sorry_error'));
				});
		},
		[ props ]
	);

	const makeLegals = () => {
		if (legals.title) {
			return <ArticleLegal item={legals} />;
		}
	};

	return (
		<div className="view">
			<Seo
				pageSeo={{
					seo_title: `Legal Pages | ${props.churchName}`,
					seo_description: 'Church Legal Pages'
				}}
			/>
			<div className="view__body">{makeLegals()}</div>
		</div>
	);
}

export default Legals;
