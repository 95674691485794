import React from 'react';

import { t } from '../../locales/translations';
import MailModal from '../modals/mail_modal';
import { useState } from 'react';
import { useContext } from 'react';
import { SeoContext } from '../wrappers/seo_wrapper';
import { useEffect } from 'react';

import LazyLoad from 'react-lazyload';

const Newsletter = (props) => {
	let info = useContext(SeoContext);
	// eslint-disable-next-line no-unused-vars
	const [modalContent, setModalContent] = useState([]);
	const [modalActive, setmodalActive] = useState(false);

	useEffect(
		() => {
			if (info.data) {
				setModalContent(info.data.mailchimp_code);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[info]
	);

	function showModal() {
		setmodalActive(true);
	}

	function handleClose() {
		// open and close dropdown
		setmodalActive(false);
	}

	function makeNewsletter(items) {
		if (items) {
			return items.map((item, id) => {
				return (
					<section key={id} className={`section--margin--large`}>
						<div className="layout--main">
							<div className="grid grid--center">
								<div className={`cell cell--6--large cell--12--small`}>
									<div className="section__body section__body--margin text--center">
										<p className="text">{item.description}</p>
									</div>
									<button onClick={showModal} className="btn btn--primary btn--center btn--big">
										Sign Up
									</button>
								</div>
							</div>
						</div>
					</section>
				);
			});
		} else {
			return <React.Fragment />;
		}
	}

	let title = t('newsletter.title');
	let description = t('newsletter.description');

	if (info.data.mailchimp_code) {
		return (
			<React.Fragment>
				<div data-aos="fade-up" data-aos-delay="220">
					<LazyLoad>
						{makeNewsletter([
							{
								title: title,
								description: description
							}
						])}
					</LazyLoad>
				</div>
				<MailModal item={modalContent} isActive={modalActive} handleClose={handleClose} />
			</React.Fragment>
		);
	} else {
		return null
	}

};

export default Newsletter;
