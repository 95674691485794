import React from 'react';
//https://www.npmjs.com/package/react-html-parser
import ReactHtmlParser from 'react-html-parser';

const ArticleLegal = props => {
	return (
		<React.Fragment>
			{props.item && (
				<div className='layout--main'>
					<article className='article article--news' data-aos='fade-in'>
						<div className='grid grid--center'>
							<div className='cell cell--10--large'>
								<div className='article__body article__body--news'>
									<div className='article__main'>
										{/*<p> {item.description} </p>*/}
										<div className='article__content font--medium gray'>
											{ReactHtmlParser(props.item.content)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</article>
				</div>
			)}
		</React.Fragment>
	);
};

export default ArticleLegal;
