import React, { Component } from 'react';
import axios from 'axios';
import { submitedPrayersUrl } from '../../helpers/api_routes';

export default class PrayerForm extends Component {

	/*

	$ curl -X POST -H "Accept: application/json" --data 'submited_prayer[email]=kvesic.mislav@gmail.com&submited_prayer[first_name]=Mislav&submited_prayer[last_name]=Kvesic&submited_prayer[content]=IprayYouPray&submited_prayer[city]=Osijek&submited_prayer[prayer_for]=YourM&submited_prayer[gdpr_accept]=1' https://cityoflondonchurch.com/api/v2/1/submited_prayers

	*/
	constructor(props) {
		super(props);
		this.state = {
			prayer_for: '',
			content: '',
			city: '',
			first_name: '',
			last_name: '',
			email: '',
			gdpr_accept: '0',
			status: '',
			statusCode: '',
			is_anonymous: '0',
			charLeft: 250,
			maxLength: 250
		};
	}


	charCounter = (e) => {
	}

	changeHandler = e => {
		this.setState({ [e.target.name]: e.target.value });

		if (e.target.maxLength === this.state.maxLength) {
			this.setState({charLeft: e.target.maxLength - e.target.value.length})
		}
	};

	checkboxHandler = e => {
		this.setState({ [e.target.name]: e.target.checked ? '1' : '0' });
	};


	submitHandler = e => {
		e.preventDefault();
		if (this.state.prayer_for.length > 0 && this.state.content.length > 0 && this.state.email.length > 0 && this.state.first_name.length > 0 && this.state.last_name.length > 0 && this.state.gdpr_accept === '1') {
			axios
			.post(submitedPrayersUrl.validate, this.state)
			.then(response => {
				axios
					.post(submitedPrayersUrl.prayers, this.state)
					.then(res => {
						this.setState({
							statusCode: res.status,
							status: res.data.status
						});
					})
					.catch(error => {
						console.log(error);
					});
			})
			.catch(error => {
				console.log(error);
			});
		}
	};

	render() {
		const { email, first_name, last_name, content, city, prayer_for, gdpr_accept } = this.state;
		return (
			<form
				action='submit'
				className='form form--border'
				onSubmit={this.submitHandler}
			>
				<fieldset className='form__fieldset'>
					<div className="input--checkbox__wrap">
						<input
							type='checkbox'
							className=''
							name='is_anonymous'
							id='is_anonymous'
							onChange={this.checkboxHandler}
						/>
						<label htmlFor="is_anonymous" className="no-margin">I want to be anonymous</label>
					</div>
				</fieldset>
				<fieldset className='form__fieldset'>
					<label htmlFor=''>Praying for (max 25. characters):</label>
					<input
						type='text'
						className='input input--text'
						name='prayer_for'
						defaultValue={prayer_for}
						onChange={this.changeHandler}
					/>
				</fieldset>
				<fieldset className='form__fieldset'>
					<label htmlFor=''>My prayer:</label>
					<textarea
						name='content'
						cols='30'
						rows='10'
						maxLength={this.state.maxLength}
						className='textarea'
						defaultValue={content}
						onChange={this.changeHandler}
					></textarea>
					<div className="align-right"><span className="gray align-right">{this.state.charLeft}</span></div>
				</fieldset>
				<fieldset className='form__fieldset'>
					<label htmlFor=''>Location (Country or City):</label>
					<input
						type='text'
						className='input input--text'
						name='city'
						defaultValue={city}
						onChange={this.changeHandler}
					/>
				</fieldset>
				<fieldset className="form__fieldset">
					<p className="gray text--large"> 
					In order for us to confirm receipt of your prayer request please provide the following details:
					</p>
				</fieldset>
				<fieldset className='form__fieldset'>
					<label htmlFor=''>First Name:</label>
					<input
						type='text'
						className='input input--text'
						name='first_name'
						defaultValue={first_name}
						onChange={this.changeHandler}
					/>
				</fieldset>
				<fieldset className='form__fieldset'>
					<label htmlFor=''>Last Name:</label>
					<input
						type='text'
						className='input input--text'
						name='last_name'
						defaultValue={last_name}
						onChange={this.changeHandler}
					/>
				</fieldset>
				<fieldset className='form__fieldset'>
					<label htmlFor=''>Your Email:</label>
					<input
						type='email'
						className='input input--text'
						name='email'
						defaultValue={email}
						onChange={this.changeHandler}
					/>
				</fieldset>
				<fieldset className='form__fieldset'>
					<div className="input--checkbox__wrap">
						<input
							type='checkbox'
							className=''
							name='gdpr_accept'
							id='gdpr_accept'
							onChange={this.checkboxHandler}
						/>
						<label htmlFor="gdpr_accept" className="no-margin">I accept that my email address will be used to provide me with an opportunity to subscribe to regular emails from Digital Church Platform</label>
					</div>
				</fieldset>
				<input
					type='submit'
					className={`btn btn--full ${
						prayer_for.length > 0 && content.length > 0 && email.length > 0 && first_name.length > 0 && last_name.length > 0 && gdpr_accept === '1'
							? 'btn--primary'
							: 'btn--disabled'
					}`}
					value='Send'
				/>
				{this.state.status.length > 0 && (
					<div
						className={`form__status ${
							this.state.statusCode === 200
								? 'form__status--ok'
								: 'form__status--error'
						}`}
					>
						<span>{this.state.status}</span>
					</div>
				)}
			</form>
		);
	}
}
