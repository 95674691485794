import React, { useState, useEffect } from 'react';
import { mediaUrl, pageRootUrl } from '../../helpers/api_routes';
import { t } from '../../locales/translations';
import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';

const axios = require('axios');

const Sliders = (props) => {
	const [media, setMedia] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [currentSlide, setCurrentSlide] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [error, setError] = useState('');

	const generateSettings = (indexSlider) => {
		return {
			dots: false,
			infinite: false,
			speed: 220,
			slidesToShow: 3,
			arrows: true,
			focusOnSelect: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
					},
				},
			],
			afterChange: (indexOfCurrentSlide) => {
				setCurrentSlide({
					indexSlider,
					indexOfCurrentSlide,
				});
			},
		};
	};

	const settings1 = generateSettings('Slider1');
	const settings2 = generateSettings('Slider2');

	function getMedia() {
		axios
			.get(mediaUrl.show)
			.then(function (response) {
				props.setSectionsLoaded && props.setSectionsLoaded("gallery");
				// backend should take care off pagination
				setMedia({
					contemporary: response.data.media.items.filter(
						(obj) => obj.type === 'contemporary'
					),
					historical: response.data.media.items.filter(
						(obj) => obj.type !== 'contemporary'
					),
				});
			})
			.catch(function (error) {
				setError(t('sorry_error'));
			});
	}

	useEffect(() => {
		getMedia();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const makeGallery = (items) => {
		if (items) {
			return items.map((item, i) => {
				return (
					<LazyLoad key={i}>
						<div className='relative'>
							<img
								key={`media_${i}`}
								src={`${pageRootUrl}/${item.image}`}
								alt={item.description}
							/>
							<div className='gallery-item__name'>
								{item.title}
							</div>

							{ item.caption && item.caption.length && 
								<div className='gallery-item__caption'>
									{item.caption}
								</div>
							}
						</div>
					</LazyLoad>
				);
			});
		} else {
			return null;
		}
	};

	return (
		<div className='layout--main'>
			<footer className='' data-aos='fade-in'>
				{/*<img src={`${pageRootUpdate}${item.image}`} alt={item.title} />*/}
				<div className='gallery-section'>
					<h2>Contemporary</h2>
					<div className='slider--gallery'>
						<Slider {...settings1}>
							{makeGallery(media.contemporary)}
						</Slider>
					</div>
				</div>

				<div className='gallery-section'>
					<h2>Historical</h2>
					<div className='slider--gallery'>
						<Slider {...settings2}>
							{makeGallery(media.historical)}
						</Slider>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Sliders;
