import React from 'react';
import { Link } from 'react-router-dom';
import { pageRootUpdate } from '../../helpers/api_routes';
import ReactHtmlParser from 'react-html-parser';
import LazyLoad from 'react-lazyload';
//https://www.npmjs.com/package/react-html-parser

const Cta = props => {
	const item = props.item;
	const key = props.id;

	return (
		<div key={key} className='cta' data-aos='fade-in'>
			<div className='cta__img'>
				<div>
					{item.image.length > 0 && (
						//right now, donations cta item is different than other items and it uses a static image instead of uploaded images
						<LazyLoad>
							<img
								src={
									item.image.includes('static/media')
										? item.image
										: `${pageRootUpdate}${item.image}`
								}
								alt='duck'
							/>
						</LazyLoad>
					)}
				</div>
				{item.secondaryImage && (
					<div>
						<img src={item.secondaryImage} alt='blob' />
					</div>
				)}
			</div>
			<div className='cta__body'>
				<h2 className='cta__title'>{item.title}</h2>
				<div className='cta__desc font--medium gray'>
					{ReactHtmlParser(item.content)}
				</div>
			</div>

      { (item.url && item.url.length) ? (
			<Link
				to={item.url}
				scroll={el => {
					setTimeout(() => {
						el.scrollIntoView(true);
						window.scrollBy({ top: -160 });
					}, 100);
				}}
				className={`btn ${
					item.important ? 'btn--primary' : 'btn--transparent'
				}`}
			>
				{' '}
				{item.button_text}{' '}
			</Link>
      ) : (null)}

      { (item.external_url && item.external_url.length) ? (
			<a
				href={item.external_url}
        target="_blank"
        rel="noopener noreferrer"
				className={`btn ${
					item.important ? 'btn--primary' : 'btn--transparent'
				}`}
      >
				{' '}
				{item.button_text}{' '}
			</a>
        ) : (null)}
		</div>
	);
};

export default Cta;
