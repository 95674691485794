// used on fetch_data.js and profile header
export const maxUsedSpace = 2000000000;

// used on post_select.js
export const maxImageSize = 5; //5MB
export const maxVideoSize = 10; //10MB
export const oneMBInCharacters = 1048576;
export const assumedSizeOnDisk = (base64) => Math.ceil(base64 * (3 / 4));
export const bytesToMB = (assumedSizeOnDisk) =>
	(assumedSizeOnDisk / (1024 * 1024)).toFixed(2);

export const mail = 'sanctuaryinthecity@me.com';
export const church_of_england = 'https://www.churchofengland.org/';
export const vimeoIframe = 'https://player.vimeo.com/video/';

export const handleHashLink = () => {
	if (window.location) {
		const hash = window.location.hash;

		if (hash.length) {
			let interval;

			const getHashElement = () => {
				const element = document.querySelector(hash);

				if (element) {
					clearInterval(interval);

					setTimeout(() => {
							element.scrollIntoView();
							window.scrollBy(0, -200); // Adjust scrolling with a negative value here
					}, 500)

				} else {
					setTimeout(() => {
						clearInterval(interval);
					}, 10000);
				}
			};

			interval = setInterval(getHashElement, 100);
		}
	}
};

export const audioWidget = null;

export const getYtId = (url) => {
	var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
	var match = url.match(regExp);
	if (match && match[2].length == 11) {
		return match[2];
	} else {
		//error
	}
}