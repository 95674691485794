import React, { Component } from 'react';

// my helpers
import {
	pageRootUrl,
	lifeEventsUrl,
} from '../../helpers/api_routes';
import { t } from '../../locales/translations';

import { mail } from '../../helpers/common_constants';
import Tabs from '../../components/tabs/tabs';

import VideoSlider from '../../components/video_slider';

import SubmitedPrayers from '../../components/prayers';
import { Link } from 'react-router-dom';
import Seo from '../../components/seo/seo';
import EventCalendar from './event_calendar';
import { handleHashLink } from '../../helpers/common_constants';

// for fetching data from server
const axios = require('axios');

class ServiceShow extends Component {
	constructor(props) {
		super(props);
		this.makeTabItems = this.makeTabItems.bind(this);
		this.state = {
			currentUser: this.props.currentUser,
			lifeEvents: [],
			eventTypes: [],
			awaitingServer: true,
			errors: null,
			modalActive: false,
			tabItems: [],
			timeZone: 'GMT',
			sections: ["calendar", "videos", "prayers", "life_events", "learning"],
			sectionsLoaded: [],
			eventTimeFormat: {
				hour: 'numeric',
				minute: '2-digit',
				meridiem: 'short',
			},
			pageLoaded: false
		};

		this.setSectionsLoaded = this.setSectionsLoaded.bind(this);

	}

	setSectionsLoaded(section) {
		if (!this.state.sectionsLoaded.includes(section)) {
			this.setState(prevState => {
				return { sectionsLoaded: [...prevState.sectionsLoaded, section] }
			})
		}
	}

	componentDidUpdate() {
		if (!this.state.pageLoaded) {
			if (this.state.sectionsLoaded.includes("calendar" && "videos" && "prayers" && "life_events" && "learning")) {
				this.setState({ pageLoaded: true });
			}
		}

		if (this.state.pageLoaded) {
			handleHashLink();
		}
	}


	getLifeEvents() {
		var that = this;
		axios
			.get(lifeEventsUrl.index)
			.then(function (response) {
				that.setSectionsLoaded("life_events");
				// backend should take care off pagination
				that.setState({
					lifeEvents: response.data.events.items,
					awaitingServer: false,
				});
			})
			.catch(function (error) {
				that.setState({
					errors: t('sorry_error'),
					awaitingServer: false,
				});
			});
	}

	makeLifeEvents(items) {
		if (items) {
			return items.map((item, id) => {
				return (
					<div
						key={id}
						className='text-with-img text-with-img--life-event'
					>
						<div className='text-with-img__body white'>
							<h2>{item.title}</h2>
							<div className='font--medium'>
								{item.description}
							</div>
							<a
								className='btn btn--primary btn--margin'
								href={item.link}
								target='_blank'
								rel='noopener noreferrer'
							>
								{t('find_out_more')}
							</a>
							<a
								className='btn btn--primary'
								href={`mailto:${mail}?subject=${item.title}`}
							>
								{t('contact_us')}
							</a>
						</div>
						<div className='text-with-img__img'>
								<img
									src={`${pageRootUrl}/${item.image}`}
									alt={item.title}
								/>
						</div>
					</div>
				);
			});
		} else {
			return <React.Fragment />;
		}
	}

	makeTabItems() {
		this.setState({
			tabItems: [
				{
					title: 'Books to Read',
					link:
						'https://www.chpublishing.co.uk/category/christian-books/books',
				},
				{
					title: 'Podcasts to Listen to',
					link:
						'https://podcasts.apple.com/gb/podcast/the-church-of-england/id264931305',
				},
				{
					title: 'Apps to Download',
					link: 'https://www.chpublishing.co.uk/apps',
				},
				{
					title: 'Videos to Watch',
				},
			],
		});
	}

	componentDidMount() {
		this.makeTabItems();
		this.getLifeEvents();
	}

	render() {
		return (
			<div className='view'>
				<Seo
					pageSeo={{
						seo_title: `Faith | ${this.props.churchName}`,
						seo_description: `You are always welcome here, whoever you are and whatever you do or don't believe.`,
					}}
				/>
				<div className='view__body' data-aos='fade-in'>
					<section className='section--padded--bottom' id='services'>
						<div className='layout--main'>
							<div className='section--header'>
								<div className='grid'>
									<div className='cell cell--6--large cell--12--small'>
										<h1 className=''>
											{t('prayer_and_worship.title')}
										</h1>
										<p className='font--medium gray'>
											{t(
												'prayer_and_worship.description'
											)}
										</p>
									</div>
								</div>
							</div>
							<EventCalendar info={this.props.info} setSectionsLoaded={this.setSectionsLoaded} />
						</div>
					</section>
					<section
						className='section section--padded--tb section--light'
						id='videos'
					>
						<div className='layout--main'>
							<div className='section--header'>
								<div className='grid'>
									<div className='cell cell--5--large cell--12--small'>
										<h1 className='no-margin'>
											{t('videos.title')}
										</h1>
									</div>
								</div>
							</div>
						</div>
						<div className='margin--double'>
							<VideoSlider setSectionsLoaded={this.setSectionsLoaded} />
						</div>
						<div className='layout--main'>
							<div className=''>
								<div className='grid'>
									<div className='cell cell--8--large' />
									<div className='cell cell--4--large'>
										<div className='flex flex-end'>
											<a
												href={`mailto:${this.props.info
													? this.props.info.email
													: ''
													}`}
												className='btn btn--primary'
											>
												{t('contact_us')}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section
						className='section section--padded--tb'
						id='prayers'
					>
						<div className='layout--main'>
							<div className='section--header'>
								<div className='grid'>
									<div className='cell cell--6--large cell--12--small'>
										<h1 className='black no-margin'>
											Prayer Wall
										</h1>
									</div>
								</div>
							</div>
						</div>
						<div>
							<SubmitedPrayers setSectionsLoaded={this.setSectionsLoaded} />
							<div className='center-inblock btn--see-more'>
								<Link
									to='/prayer-wall'
									className='btn btn--primary'
								>
									See More
								</Link>
							</div>
						</div>
					</section>

					<section
						className='section section--primary section--padded--tb'
						id='events'
					>
						<div className='layout--main'>
							<div className='section--header'>
								<div className='grid'>
									<div className='cell cell--6--large cell--12--small'>
										<h1 className='white'>
											{t('life_events.title')}
										</h1>
									</div>
								</div>
							</div>
						</div>
						<div className='layout--main'>
							{this.makeLifeEvents(this.state.lifeEvents)}
						</div>
					</section>
					<section className='section--padded--tb' id='learning'>
						<div className='layout--main'>
							<h1>{t('learning_and_faith.title')}</h1>
							<Tabs items={this.state.tabItems} setSectionsLoaded={this.setSectionsLoaded} />
						</div>
					</section>
				</div>

				<p>{this.state.errors}</p>
			</div >
		);
	}
}

export default ServiceShow;
