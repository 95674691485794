import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { t } from "../../locales/translations";
import { useState } from "react";
import { partnersUrl, pageRootUpdate } from "../../helpers/api_routes";
import { useEffect } from "react";
import LazyLoad from "react-lazyload";

const axios = require("axios");

const TabsPartnersWrapper = props => {
  const [partners, setPartners] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");

  const makeTabs = () => {
    let keys = Object.keys(partners).filter(item => item !== "title");
		let filteredPartners = keys.map(key => partners[key]).filter(item => item.items.length > 0);

    return (
      filteredPartners &&
      filteredPartners.map((item, index) => {
        return (
          <Tab key={index}>
            <span>{item.title}</span>
          </Tab>
        );
      })
    );
  };

  const getPartners = () => {
    axios
      .get(partnersUrl.index)
      .then(function (response) {
        setPartners(response.data.partners);
      })
      .catch(function (error) {
        setError(t("sorry_error"));
      });
  };

  const makePartners = () => {
    let keys = Object.keys(partners).filter(item => item !== "title");
		let filteredPartners = keys.map(key => partners[key]).filter(item => item.items.length > 0);
	
    return filteredPartners.map((partner, index) => {
      return (
        <TabPanel key={"content_" + index}>
          <div className="tab-content">
            <div className="partners">
              {partner.items.map(item => (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="partner">
                  <div className="partner__img">
                    <LazyLoad>
                      <img
                        src={`${pageRootUpdate}/${item.image}`}
                        alt={item.title}
                      />
                    </LazyLoad>
                  </div>
                  <div>{item.title}</div>
                </a>
              ))}
            </div>
          </div>
        </TabPanel>
      );
    });
  };

  useEffect(() => {
    getPartners();
  }, []);

  if (partners) {
    return (
      <React.Fragment>
        <div className="layout--main">
          <Tabs>
            <TabList>{makeTabs()}</TabList>
            {makePartners()}
          </Tabs>
        </div>
      </React.Fragment>
    );
  } else return null;
};

export default TabsPartnersWrapper;
