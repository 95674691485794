import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = props => {
	return (
		<React.Fragment>
			<Link
				className='btn btn--transparent margin--double'
				to={props.breadcrumbs_route}
			>
				Back to {props.breadcrumbs_text}
			</Link>
			{/* <div className='breadcrumbs'>
				<Link to={props.breadcrumbs_route}>{props.breadcrumbs_text}</Link>
				<div>{props.breadcrumbs_page}</div>
			</div> */}
		</React.Fragment>
	);
};

export default Breadcrumbs;
