import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { t } from "../locales/translations";
import { Link } from "react-router-dom";
import { audioWidget } from "../helpers/common_constants";

const Subnav = props => {
  
  return (
    <div className="subnav">
      <div className="layout--main">
        {props.subnav === "about" && (
          <React.Fragment>
            <AnchorLink href="#history" offset="200">
              History
            </AnchorLink>
            <AnchorLink href="#team" offset="200">
              Team
            </AnchorLink>
            <AnchorLink href="#venues" offset="100">
              Venues
            </AnchorLink>
            <AnchorLink href="#gallery" offset="200">
              Gallery
            </AnchorLink>
            {audioWidget && (
              <AnchorLink href="#audio" offset="200">
                Audio Tour
              </AnchorLink>
            )}
          </React.Fragment>
        )}
        {props.subnav === "faith" && (
          <React.Fragment>
            <AnchorLink href="#services" offset="200">
              Calendar
            </AnchorLink>
            <AnchorLink href="#videos" offset="200">
              <span className="show--large">Online</span> worship
            </AnchorLink>
            <AnchorLink href="#prayers" offset="200">
              <span className="show--large">Your</span> Prayers
            </AnchorLink>
            <AnchorLink href="#events" offset="200">
              {t("life_events.title")}
            </AnchorLink>
            <AnchorLink href="#learning" offset="100">
              Learning
            </AnchorLink>
          </React.Fragment>
        )}
        {props.subnav === "news-items" && (
          <React.Fragment>
            <Link to={"/news-items"} className="active subnav__tab">
              <span className="hide--large">The Church</span>
              <span className="hide--small show--large">
                {t("news_items.title")} {props.info.name}
              </span>
            </Link>
            <Link to={"/external-news"} className="subnav__tab">
              <span className="hide--large">The Diocese</span>
              <span className="hide--small show--large">
                {t("news_external.title")}
              </span>
            </Link>
            <Link to={"/church-news"} className="subnav__tab">
              <span className="hide--large">The Church of England</span>
              <span className="hide--small show--large">
                {t("church_news.title")}
              </span>
            </Link>
          </React.Fragment>
        )}
        {props.subnav === "external-news" && (
          <React.Fragment>
            <Link to={"/news-items"} className="subnav__tab">
              <span className="hide--large">The Church</span>
              <span className="hide--small show--large">
                {t("news_items.title")} {props.info.name}
              </span>
            </Link>
            <Link to={"/external-news"} className="active subnav__tab">
              <span className="hide--large">The Diocese</span>
              <span className="hide--small show--large">
                {t("news_external.title")}
              </span>
            </Link>
            <Link to={"/church-news"} className="subnav__tab">
              <span className="hide--large">The Church of England</span>
              <span className="hide--small show--large">
                {t("church_news.title")}
              </span>
            </Link>
          </React.Fragment>
        )}
        {props.subnav === "church-news" && (
          <React.Fragment>
            <Link to={"/news-items"} className="subnav__tab">
              <span className="hide--large">The Church</span>
              <span className="hide--small show--large">
                {t("news_items.title")} {props.info.name}
              </span>
            </Link>
            <Link to={"/external-news"} className="subnav__tab">
              <span className="hide--large">The Diocese</span>
              <span className="hide--small show--large">
                {t("news_external.title")}
              </span>
            </Link>
            <Link to={"/church-news"} className="active subnav__tab">
              <span className="hide--large">The Church of England</span>
              <span className="hide--small show--large">
                {t("church_news.title")}
              </span>
            </Link>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Subnav;
