const version = 'v2';

//TODO production will have a different rootPage
var pageRoot = 'https://cityoflondonchurch.com/';
var churchId = 3;

// TODO add "api" instead od demo_api
//var staging = "demo_api";
var staging = 'api';

export const pageRootUpdate = pageRoot;

//link on web to be redirected
//export const policyUrlArtist = `${pageRoot}/documents/2018.03.27.UMYE-General_Terms_and_Conditions_of_Use_for_ARTISTS.pdf`;
//export const policyUrlViewer = `${pageRoot}/documents/2018.03.27.UMYE-General_Terms_and_Conditions_of_Use_for_VIEWERS.pdf`;

var root = `${pageRoot}/${staging}/${version}/${churchId}`;
// var demoRoot = `${pageRoot}/demo_api/${version}`

export const app_root = 'https://cityoflondonchurch.com';
export const rootUrl = root;
export const pageRootUrl = pageRoot;

export const newsItemsUrl = {
  index: root + '/news_items.json',
  show: root + '/news_items/',
};

export const eventsUrl = {
  index: root + '/events.json',
  show: root + '/events/',
};

export const callToActionsUrl = {
  index: root + '/call_to_actions.json',
};

export const externalNewsUrl = {
  index: root + '/external_news_items.json',
  show: root + '/external_news_items/',
};

export const whatsOnsUrl = {
  index: root + '/what_is_ons.json',
  show: root + '/what_is_ons/',
};

export const resourcesUrl = {
  index: root + '/resources/index.json',
  show: root + '/resources/show.json',
};

export const tweetsUrl = {
  index: root + '/tweets.json',
  show: root + '/tweets/',
};

export const servicesUrl = {
  index: root + '/services.json',
  show: root + '/services/',
};

export const lifeEventsUrl = {
  index: root + '/events.json',
};

export const pages = {
  about: root + '/pages/about.json',
};

export const aboutsUrl = {
  show: root + '/abouts.json',
};

export const missionUrl = {
  mission: root + '/abouts.json',
};

export const safeguardingUrl = {
  show: root + '/template_pages/safeguarding.json',
};

export const legalsUrl = {
  index: root + '/template_pages.json',
  show: root + '/template_pages/',
};

export const ourCommunitiesUrl = {
  index: root + '/our_communities.json',
  show: root + '/our_communities/',
};

export const bookingsUrl = {
  index: root + '/venue_hires.json',
  texts: root + '/venue_hire_texts/1.json',
  show: root + '/venue_hires/',
};

export const contactUrl = {
  show: root + '/contact_infos.json',
  team: root + '/team_members.json',
};

export const donationsUrl = {
  index: root + '/donations.json',
  show: root + '/donations/',
};

export const teamUrl = {
  index: root + '/team_members.json',
};

export const mediaUrl = {
  show: root + '/media.json',
};

export const booksUrl = {
  index: root + '/books.json',
};

export const podcastsUrl = {
  index: root + '/podcasts.json',
};

export const appsUrl = {
  index: root + '/app_items.json',
};

export const videosUrl = {
  index: root + '/videos.json',
};

export const eventTypesUrl = {
  index: root + '/event_types.json',
};

export const contactMessagesUrl = {
  messages: root + '/contact_messages',
  validate: root + '/contact_messages/validate',
};

export const submitedPrayersUrl = {
  prayers: root + '/submited_prayers',
  validate: root + '/submited_prayers/validate'
}

export const partnersUrl = {
  index: root + '/church_partners.json',
};

export const onlineVideos = {
  index: root + '/online_videos.json',
};

export const submitedPrayersListUrl = {
  index: root + '/submited_prayers.json'
}

export const submitedLikesUrl = {
  likes: root + '/submited_prayers'
}

export const searchUrl = root + `/search`

/* export const demoServices = {
  services: demoRoot + "/services/show.json"
} */

/* * https://cityoflondonchurch.com/api/v1/external_news_items.json
 * https://cityoflondonchurch.com/api/v1/external_news_items/[ID].json */

