import React from 'react';
import SubmitedPrayers from '../../components/prayers';
import Seo from '../../components/seo/seo';

const SubmitPrayer = (props) => {
	return (
		<div className="view">
			<Seo pageSeo={{ seo_title: `Prayer Wall | ${props.churchName}`, seo_description: `Prayers submitted by our community` }} />
			<div className="view__body">
				<div className="layout--main">
					<div className="section--header">
						<div className="grid">
							<div className="cell cell--6--large cell--12--small">
								<h1 className="black no-margin">Prayer Wall</h1>
							</div>
						</div>
					</div>
				</div>
				<div>
					<SubmitedPrayers showAll={true} />
				</div>
			</div>
		</div>
	);
};

export default SubmitPrayer;
