import React, { useEffect, useState } from "react";
//https://www.npmjs.com/package/react-html-parser my components my helpers

import BookingsIndex from "../bookings";
import ReactHtmlParser from "react-html-parser";

import { pageRootUpdate } from "../../helpers/api_routes";
import Team from "../teams/team";
import LazyLoad from "react-lazyload";
import { audioWidget } from "../../helpers/common_constants";
import Sliders from "./sliders";

const ArticleAbout = props => {
  // eslint-disable-next-line no-unused-vars
  const [aboutsLoaded, setAboutsLoaded] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState([]);

  useEffect(() => {
    setAboutsLoaded(props.abouts.length ? true : false);
  }, [props]);

  const makeAbouts = items => {
    if (items && items.length > 0) {
      return items.map((item, id) => {
        return (
          <React.Fragment key={`fragment_${id}`}>
            <div
              key={`about_${id}`}
              id={item.title.toLowerCase().replace(/ /g, "_")}
              className="text-with-img"
              data-aos="fade-in">
              <div className="text-with-img__body white" data-aos="fade-in">
                <h2>{item.title}</h2>
                <div className="font--medium">
                  {ReactHtmlParser(item.content)}
                </div>
              </div>
              <div className="text-with-img__img">
                <LazyLoad>
                  <img
                    src={`${pageRootUpdate}/${item.image}`}
                    alt={item.title}
                  />
                </LazyLoad>
                <div className="text-with-img__img-description white">
                  {item.image_description}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      });
    } else {
      return <React.Fragment />;
    }
  };

  return (
    <div className={`${aboutsLoaded ? "loader loader--loaded" : "loader"}`}>
      <article className="article article--about" data-aos="fade-in">
        {props.abouts && (
          <div className="section section--primary section--padded--tb">
            <div className="layout--main">{makeAbouts(props.abouts)}</div>
          </div>
        )}
        <div id="team">
          <Team setSectionsLoaded={props.setSectionsLoaded} />
        </div>

        <div id="venues">
          <BookingsIndex setSectionsLoaded={props.setSectionsLoaded} />
        </div>

        <section className="section--padded--tb" id="gallery">
          <div className="layout--main">
            <h1 className="center-inblock">Gallery</h1>
          </div>
          {/* this is the gallery */}

          <Sliders setSectionsLoaded={props.setSectionsLoaded} />
        </section>

        {audioWidget && (
          <section className="section--padded" id="audio">
            <div className="layout--main">
              <h1 className="center-inblock">Audio Tour</h1>
            </div>
            <div>{ReactHtmlParser(audioWidget)}</div>
          </section>
        )}
      </article>
    </div>
  );
};

export default ArticleAbout;
