import React, { useEffect, useRef } from "react";

const OpeningTimesDropdown = ({working_days, setOpeningTimesState, openingTimesState}) => {

  const openingTimes = useRef(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {  
      document.removeEventListener("click", handleClickOutside, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = event => {
    if (openingTimes.current && !openingTimes.current.contains(event.target)) {
      setOpeningTimesState(false);
    }
  };

  return (
    <div ref={openingTimes} className="opening-times-dropdown">
      <button class="opening-times-dropdown__close" onClick={() => setOpeningTimesState(false)}>&#x2715;</button>
      {working_days.map((item, i) => {
        return (
          <React.Fragment key={i}>
            {item.monday && (
              <div key={i}>
                <span>Mon:</span>
                {item.monday}
              </div>
            )}
            {item.tuesday && (
              <div key={i}>
                <span>Tue:</span>
                {item.tuesday}
              </div>
            )}
            {item.wednesday && (
              <div key={i}>
                <span>Wed:</span>
                {item.wednesday}
              </div>
            )}
            {item.thursday && (
              <div key={i}>
                <span>Thu:</span>
                {item.thursday}
              </div>
            )}
            {item.friday && (
              <div key={i}>
                <span>Fri:</span>
                {item.friday}
              </div>
            )}
            {item.saturday && (
              <div key={i}>
                <span>Sat:</span>
                {item.saturday}
              </div>
            )}
            {item.sunday && (
              <div key={i}>
                <span>Sun:</span>
                {item.sunday}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default OpeningTimesDropdown;
