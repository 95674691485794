import React, { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { search_icon } from '../../helpers/image_paths';

const SearchForm = (props) => {
	const inputRef = useRef(null);
	const [formActive, setFormActive] = useState(false);

	const changeHandler = (e) => {
		props.setSearchTerm(e.target.value)
	}

	return (
		<React.Fragment>
			<div
				className={`search-form-wrap ${formActive ? 'active' : ''
					}`}
			>
				<button
					onClick={() => {
						setFormActive(true);
						setTimeout(() => {
							inputRef.current.focus();
						}, 220)
					}}
				>
					<ReactSVG src={search_icon} />
				</button>
				<div className='search-form-wrap__form'>
					<form
						action='submit'
						className='form form--search'
						onSubmit={e => {
							e.preventDefault();
							props.searchHandler(props.searchTerm);
						 }}
					>
						<fieldset className='form__fieldset relative'>
							<input
								ref={inputRef}
								type='text'
								className='input input--text'
								name='search'
								defaultValue={props.searchTerm}
								placeholder='Search'
								onChange={e => { changeHandler(e) }}
								onBlur={(e) => {
									setFormActive(false);
									props.setSearchTerm(null);
									inputRef.current.value = '';
								}}
							/>
							<button type='button' className='search-form-wrap__close'>
								<span>&times;</span>
							</button>
						</fieldset>
					</form>
				</div>
			</div>
		</React.Fragment>
	)
}


export default SearchForm;

