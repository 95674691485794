import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

// redux
/*
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { addCurrentUserAction } from '../../actions/current_user'
*/

// my components
import Tweets from "../../components/tweets/tweets";

// my helpers
import { newsItemsUrl, pageRootUpdate } from "../../helpers/api_routes";
import { t } from "../../locales/translations";
import { useEffect } from "react";
import { useState } from "react";

import LazyLoad from "react-lazyload";
import Seo from "../../components/seo/seo";

// import SocialIcons from '../../components/buttons/social_icons';

// for fetching data from server
const axios = require("axios");

const NewsItemsIndex = props => {
  const [newsItems, setNewsItems] = useState([]);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    axios
      .get(newsItemsUrl.index)
      .then(function (response) {
        // backend should take care off pagination
        setNewsItems(response.data.news_items.items);
      })
      .catch(function (error) {
        setErrors(t("sorry_error"));
      });
  }, []);

  function makeNewsItems(items) {
    if (items.length > 0) {
      return items.map(item => {
        return (
          <div
            key={item.id}
            className="card card--horizontal card--news"
            data-aos="fade-up">
            <div className="card__bg" />
            <div className="card__img">
              <LazyLoad>
                <img src={`${pageRootUpdate}${item.image}`} alt="news" />
              </LazyLoad>
            </div>
            <div className="card__body black">
              <p className="color-primary">
                <span>
                  {moment(item.publish_at, "YYYY-MM-DD").format("DD.MM.YYYY")}
                </span>
              </p>
              <div className="text--xlarge card__title">{item.title}</div>
              <p className="card__copy black">{item.description}</p>
            </div>
            {/* <div className='card__social'>
								<SocialIcons item={item} />
							</div> */}
            <div className="card__btn">
              <Link to={`/news-item/${item.slug}`} className="overlay-link">
                {t("read_more")}
              </Link>
            </div>
          </div>
        );
      });
    } else {
      return <React.Fragment />;
    }
  }

  return (
    <div className="view--news">
      <Seo
        pageSeo={{
          seo_title: `News from the Church | ${props.churchName}`,
          seo_description: `Checkout the latest news from ${
            props.churchName ? props.churchName : "Your Church"
          }`,
        }}
      />
      <div className="layout--main">
        {/*NewsItems*/}
        <div className="grid">
          <div className="cell cell--12--large cell--12--small">
            <section className="section--offset section--primary section--padded--tb">
              <div className="list">{makeNewsItems(newsItems)}</div>
              <p>{errors}</p>
            </section>
          </div>
        </div>

        <section className="tweets-section">
          <Tweets handles={["StJohnsE15"]} />
        </section>
      </div>
    </div>
  );
};

export default NewsItemsIndex;
