import React, { useState, useEffect } from 'react';
import { donationsUrl, pageRootUrl } from '../../helpers/api_routes';
import { t } from '../../locales/translations';
import Seo from '../../components/seo/seo';
import Breadcrumbs from '../../components/breadcrumbs';
import LazyLoad from 'react-lazyload';
import ReactHtmlParser from 'react-html-parser';
import { donationsImg } from '../../helpers/image_paths';
import SectionLoader from '../../components/loaders/section_loader';
import { ifString } from '../../helpers/if_string';

// for fetching data from server
const axios = require('axios');

function Donations(props) {

	const [ donations, setDonations ] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [ error, setError ] = useState('');

	useEffect(
		() => {
			axios
      	.get(`${donationsUrl.index}`)
				.then(function(response) {
					setDonations(response.data.donation.item);
				})
				.catch(function(error) {
					setError(t('sorry_error'));
				});
		},
		[ props ]
	);

	if (donations) {
		let image = `${pageRootUrl}${donations.image}`;
		return (
			<div className='view'>
				<Seo
					pageSeo={{
						seo_image: image,
						seo_title: ifString(donations.seo_title, donations.title),
						seo_description: ifString(donations.seo_description, donations.description)
					}}
				/>
				<div className='view__body'>
					<div className='layout--main'>
						<Breadcrumbs
							breadcrumbs_route={'/'}
							breadcrumbs_text={`Homepage`}
							breadcrumbs_page={donations.id}
						/>
						<article className='article article--news'>
							<header className='article__header' data-aos='fade-in'>
								<LazyLoad>
									{donations.image && donations.image.length > 0 ? (
										<img src={`${pageRootUrl}/${donations.image}`} alt={donations.title} />
									) : (
										<img src={donationsImg} alt={donations.title} />
									)}
								</LazyLoad>
							</header>
							<div className='grid grid--center'>
								<div className='cell cell--12--large cell--12--small'>
									<div className='article__body article__body--news' data-aos='fade-in'>
										<div className='article__main'>
											<h1>{donations.title}</h1>
											{/*<p> {item.description} </p>*/}
											<div className='article__content font--medium gray'>
												{ReactHtmlParser(donations && donations.content)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</article>
					</div>
				</div>
			</div>
		);
	} else {
		return <SectionLoader />;
	}
}

export default Donations;
