import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Newsletter from './newsletters/newsletter';

import SocialLinks from './social_links/social_links';
import { diocese_of_london, church_of_england, digital_church_platform, cheimsford_logo } from '../helpers/image_paths';

import { contactUrl } from '../helpers/api_routes';
import LazyLoad from 'react-lazyload';

// for fetching data from server eslint-disable-next-line const axios =
const axios = require('axios');

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: this.props.currentUser,
			events: [],
			contacts: [],
			awaitingServer: false,
			errors: null,
		};
	}

	getScript(src) {
		const script = document.createElement('script');
		script.src = src;
		script.async = true;
		script.id = 'izi-travel-widget';
		script.charset = 'UTF-8';
		document.body.appendChild(script);
	}

	componentDidMount() {
		this.getScript('https://widget.izi.travel/api.js');
		var that = this;
		// get events
		axios
			.get(contactUrl.show)
			.then(function (response) {
				that.setState({
					contacts: response.data.contact_infos.item[0],
					awaitingServer: false,
				});
			})
			.catch(function (error) {
				that.setState({
					errors: error,
					awaitingServer: false,
				});
			});
	}

	make_links(items) {
		var i = 0;
		return items.map((item) => {
			let link = item.isRoot ? '/' : `/${item.slug}`;
			let image = item.image ? item.image : null;
			let text = item.text ? item.text : null;
			let logoText = item.logoText ? item.logoText : null;
			let page = item.page ? item.page : null;
			i++;

			return (
				<React.Fragment key={i}>
					<Link
						to={link}
						page={page}
						className={logoText ? 'nav__logo' : 'footer__link'}
					>
						{/* LINK can be an image*/}
						{image ? <img src={image} alt={`${link}`} /> : null}

						{/* LINK can be an textual logo */}
						{logoText ? <span>{`${logoText}`}</span> : null}

						{/* LINK can be some text*/}
						{text ? <span>{`${text}`}</span> : null}
					</Link>
				</React.Fragment>
			);
		});
	}

	render() {
		return (
			<div>
				<Newsletter />
				<footer className='footer'>
					<div className='footer__body'>
						<div className='layout--main'>
							<div className='footer__top'>
								<div className='grid'>
									<div className='cell cell--4--large cell--12--small'>
										<div className='footer__group'>
											{this.make_links([
												{
													slug: 'what-is-ons',
													text: "What's on",
													// highlight: true
												},
												{
													slug: 'about',
													text: 'About',
												},
												{
													slug: 'news-items',
													text: 'News',
												},
												{
													slug: 'faith',
													text: 'Faith',
												},
												{
													slug: 'our-community',
													text: 'Our Community',
												},
												{
													slug: 'safeguarding',
													text: 'Safeguarding',
												},
											])}
										</div>
									</div>
									<div className='cell cell--6--large cell--12--small'>
										<div className='footer__group footer__group--row'>
											<p>
												{this.state.contacts.name}
												<br />
												{this.state.contacts.address}
												<br />
												{this.state.contacts
													.registered_charity_number && (
														<span>
															Registered Charity No:
															{
																this.state.contacts
																	.registered_charity_number
															}
														</span>
													)}
											</p>
											<p>
												{this.state.contacts.phone && <div>t: {this.state.contacts.phone}</div>}
												<a
													href={`mailto:${this.state.contacts.email}`}
													className='color-primary'
												>
													{this.state.contacts.email}
												</a>
											</p>
										</div>
									</div>
									<div className='cell cell--2--large cell--12--small'>
										<div className='footer__group'>
											<SocialLinks show_name={true} />
										</div>
									</div>
								</div>
							</div>
							<div className='footer__logos'>
								<div>
									<a
										href='https://www.churchofengland.org/'
										target='_blank'
										rel='noopener noreferrer'
									>
										<LazyLoad>
											<img
												src={church_of_england}
												alt='Church of England'
											/>
										</LazyLoad>
									</a>
									<a
										href='#'
										rel='noopener noreferrer'
									>
										<LazyLoad>
											<img
												src={cheimsford_logo}
												alt='Cheimsford'
											/>
										</LazyLoad>
									</a>
								</div>
							</div>
							<div className='footer__bottom'>
								<div className='copyright'>
									© Demo Church. All rights
									reserved {new Date().getFullYear()}.
								</div>
								<div className='footer__links'>
									{this.make_links([
										{
											slug: 'legals/terms-of-service',
											page: 'terms-of-service',
											text: 'Terms of Service',
										},
										{
											slug: 'legals/privacy-policy',
											page: 'privacy-policy',
											text: 'Privacy',
										},
										{
											slug: 'safeguarding',
											text: 'Safeguarding',
										},
									])}
								</div>
								<div>
									<a
										href='https://www.thedigitalchurchplatform.com/'
										target='_blank'
										rel='noopener noreferrer'
										className="logo-digital"
									>
										<span>Created on The Digital Church Platform&reg;</span>
										<img
											src={digital_church_platform}
											alt='Digital Church Platform'
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}

export default Footer;
//if redux use below as export
/*
function mapStateToProps(state, props) {
	return {
		currentUser: state.currentUser
	};
};

function mapDispatchToProps(dispatch) {
	return {
		action: bindActionCreators(addCurrentUserAction, dispatch)
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
*/
