/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router-dom';
import { app_root } from '../../helpers/api_routes';
import { headerImg } from '../../helpers/image_paths';
import { SeoContext } from '../wrappers/seo_wrapper';

const Seo = (props) => {
	const location = useLocation();
	let seoInfo = useContext(SeoContext);

	useEffect(
		() => {
			seoInfo.setData({
				...props.pageSeo,
				location: `${app_root}${location.pathname}`
			});
		},
		[ props.pageSeo ]
	);

	if (props.pageSeo && props.pageSeo.seo_title) {

		let parts = location.pathname.split('/');
		let partsNew = [...parts];
		partsNew[parts.length - 1] = props.pageSeo.canonical;
		partsNew = partsNew.join('/');

		let seo_image = props.pageSeo.seo_image ? props.pageSeo.seo_image : app_root + headerImg;

		return (
			<React.Fragment>
				<Helmet>
					<title>{ReactHtmlParser(props.pageSeo.seo_title)}</title>
					<meta name='description' content={ReactHtmlParser(props.pageSeo.seo_description)} />
					<meta property='og:description' content={ReactHtmlParser(props.pageSeo.seo_description)} />
					<meta property='og:title' content={ReactHtmlParser(props.pageSeo.seo_title)} />
					<meta property='og:image' content={seo_image} />
					<meta name='image' content={props.pageSeo.image} />
					<meta property='twitter:title' content={ReactHtmlParser(props.pageSeo.seo_seo_title)} />
					<meta property='twitter:description' content={ReactHtmlParser(props.pageSeo.seo_description)} />
					<meta property='twitter:image' content={seo_image} />
					{props.pageSeo.canonical && partsNew ? <link rel="canonical" href={app_root + partsNew} /> : null}
					{props.pageSeo.disallow && <meta name="robots" content="none" />}
				</Helmet>
			</React.Fragment>
		);
	} else {
		return null;
	}
};

export default Seo;
