import React from 'react';
import { Link } from 'react-router-dom';
import Seo from '../components/seo/seo';

function NotFound() {
	const location = window.location.href;
	return (
		<div className='not-found'>
			<Seo
				pageSeo={{
					seo_title: `Page not Found - ${location}`,
					seo_description: `Page ${location} doesn't exist`,
					disallow: true
				}}
			/>
			<h1>404 - Page not found</h1>
			<p>
				Page <span className='color-accent'>{location}</span> doesn't
				exist
			</p>
			<Link to={'/'} className='btn btn--primary'>
				Return to Our Homepage
			</Link>
		</div>
	);
}

export default NotFound;
