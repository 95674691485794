import React, { useState, useEffect, useRef } from 'react';

import {
	servicesUrl,
	pageRootUrl,
	app_root,
} from '../../helpers/api_routes';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule';
import CalendarModal from '../../components/modals/calendar_modal';

import axios from 'axios';
import moment from 'moment-timezone';
import { ReactSVG } from 'react-svg';
import { check_circle, x_circle, icon_google, icon_apple } from '../../helpers/image_paths';

// import CsvDownload from 'react-json-to-csv';
const ical = require('ical-generator');

export default function EventCalendar(props) {

	const [servicesData, setServicesData] = useState(null);
	const [services, setServices] = useState(null);
	const [servicesLegend, setServicesLegend] = useState(null);
	const [calendarData, setCalendarData] = useState(null);
	const [filterServices, setFilterServices] = useState([]);
	const [exportEvents, setExportEvents] = useState([]);
	const [eventContent, setEventContent] = useState(null);
	const [modalActive, setModalActive] = useState(false);

	const calendar = useRef(null);

	useEffect(() => {
		getServices();
	}, [])

	function getServices() {
		axios
			.get(servicesUrl.index)
			.then(function (response) {
				props.setSectionsLoaded && props.setSectionsLoaded("calendar");
				// backend should take care off pagination
				setServicesData([...response.data.services.items, ...response.data.what_is_ons.items])
			})
			.catch(function (error) {
				console.log(error)
			});
	}

	function showEventModal(info) {

		setEventContent({
			title: info.event.title,
			description: info.event.extendedProps.description,
			start: info.event.extendedProps.startTime,
			end: info.event.extendedProps.endTime,
			start_date: info.event._instance.range.start,
			end_date: info.event._instance.range.end,
			allDay: info.event.allDay
		});
		setModalActive(true);
	}

	const getCalendarEvents = () => {
		let calendarApi = calendar.current.getApi();
		let events = calendarApi.getEvents();

		if (events && events.length) {
			events = events.map(item => {
				let event = {
					id: item.id,
					title: item.title,
					description: item.extendedProps.description,
					start: item.extendedProps.start,
					end: item.extendedProps.end,
					allDay: item.allDay
				};

				if (item.extendedProps.rrule) {
					event = {
						...event,
						recurring: {
							daysOfWeek: item.extendedProps.daysOfWeek,
							endRecur: item.extendedProps.rrule.until,
							freq: item.extendedProps.rrule.freq
						}
					}
				}

				return event
			})


			function getUniqueListBy(arr, key) {
				return [...new Map(arr.map(item => [item[key], item])).values()]
			}

			let unique = getUniqueListBy(events, 'id');

			if (unique.length !== exportEvents.length) {
				setExportEvents(unique);
			}
		}
	}

	function makeServices(items) {
		if (items) {
			return items.map((item, i) => {
				// ignore if active
				let active = filterServices.find(value => value === item.title);
				let opacity = active ? '0.25' : '1'

				return (
					<button className='event-about' key={i} style={{ borderColor: item.color, opacity: opacity }} onClick={() => {
						let events = [...filterServices];

						if (active) {
							events = events.filter(value => value !== item.title);
						} else {
							events = [...events, item.title];
						}

						setFilterServices(events);
					}}>
						{item.image && (
							<div className='event-about__img'>
								<img
									src={`${pageRootUrl}/${item.image}`}
									alt=''
								/>
							</div>
						)}
						<h4 className='event-about__title bold'>
							{item.title}
						</h4>
						<div className='event-about__description font--small'>
							{item.description}
						</div>
						<div className="event-about__icon"><ReactSVG src={active ? x_circle : check_circle} /></div>
					</button>
				);
			});
		} else {
			return <React.Fragment />;
		}
	}

	function matchWeekdays(arr) {
		let weekdays = ["su", "mo", "tu", "we", "th", "fr", "sa"];
		let matchedWeekdays = arr.map((item) => {
			return weekdays[item]
		})

		return matchedWeekdays;
	}

	useEffect(() => {
		// map services for the calendar
		let arr =
			servicesData && servicesData.map((item) => {
				var itemColor = item.event_type.color ? item.event_type.color : '#854a98';

				var firstDayOfYear = moment().startOf('year').format('YYYY-MM-DD');
				var lastDayOfYear = moment().endOf('year').format('YYYY-MM-DD');

				let startTime = item.start_time_at;
				let endTime = item.end_time_at;

				let startAt = moment(item.start_at, 'YYYY-MM-DD').format('YYYY-MM-DD');
				let endAt = moment(item.end_at, 'YYYY-MM-DD').format('YYYY-MM-DD');

				let startDate = item.start_at.length ? startAt : firstDayOfYear;
				let endDate = item.end_at.length ? endAt : lastDayOfYear;


				const formatDate = (date, time) => {
					let fDate = date;
					let fTime = `T${moment(time ? time : '00:00', ["h:mm A"]).format("HH:mm:ss")}`;
					let newDate = `${fDate}${fTime}`
					return newDate;
				}

				let event = {
					id: item.id,
					color: itemColor,
					backgroundColor: itemColor,
					borderColor: itemColor,
					title: item.title,
					description: item.description,
					allDay: item.is_all_day,
					rendering: 'block',
					overlap: true,
					startSort: startTime ? moment(startTime, ["h:mm A"]).format("HH:mm") : '00:00',
					extendedProps: {
						description: item.description,
						daysOfWeek: item.days,
						startTime: startTime,
						endTime: endTime,
						start: formatDate(startDate, startTime),
						end: item.end_at.length ? formatDate(endDate, endTime) : formatDate(startDate, endTime),
						event_type: item.event_type ? item.event_type.title : `What's On`,
					}
				}

				if (item.days && item.days.length || item.is_monthly || item.is_yearly) {

					// if event is repeating, make repeating event

					event = {
						...event,
						start: formatDate(startDate, startTime),
						end: formatDate(endDate, endTime),
						rrule: {
							interval: 1,
							dtstart: formatDate(startDate, '00:00'),
							until: formatDate(endDate, '23:55'),
						},
						duration: { days: 1 },
						extendedProps: {
							...event.extendedProps,
							start: formatDate(startDate, startTime),
							end: formatDate(startDate, endTime),
						},
						type: 'recur'
					}

					if (item.is_monthly) {
						event.rrule = {
							...event.rrule,
							freq: 'monthly',
							until: '2030-12-30'
						}
					} else if (item.is_yearly) {
						event.rrule = {
							...event.rrule,
							freq: 'yearly',
							until: '2030-12-30'
						}
					} else {
						event.rrule = {
							...event.rrule,
							freq: 'weekly',
							byweekday: matchWeekdays(item.days),
						}
					}

					if (item.is_monthly || item.is_yearly || item.days.length) {
						event.extendedProps = {
							...event.extendedProps,
							rrule: event.rrule
						}
					}

					return event;

				} else {

					// if not repeating, it's a single event

					event = {
						...event,
						start: formatDate(startDate, startTime),
						end: item.end_at.length ? formatDate(endDate, endTime) : formatDate(startDate, endTime)
					}

					return event
				}
			})

		setServices(arr);

	}, [servicesData])

	useEffect(() => {
		if (servicesData) {
			let arr = servicesData.map((item, i) => {
				if (item.event_type) {
					return ({
						id: item.id,
						title: item.event_type.title,
						color: item.event_type.color,
						description: item.event_type.description,
						image: item.event_type.image,
						position: item.event_type.position,
					})
				} else {
					return ({
						id: item.id,
						title: `What's On`,
						color: '#854a98',
						description: ``,
						image: ``,
						position: ``,
					})
				}
			});

			arr.sort(function (a, b) {
				return a.position - b.position;
			});

			// remove duplicate event types from the legend
			arr =
				arr.filter(
					(item, i) =>
						i ===
						arr.findIndex(
							(elem) =>
								elem.title === item.title &&
								elem.color === item.color
						)
				);

			setServicesLegend(arr);
		}
	}, [servicesData]);

	useEffect(() => {
		let arr = exportEvents && exportEvents.map(item => {

			if (!filterServices.find(value => value === item.title)) {

				let obj = {
					start: item.start,
					end: item.end,
					summary: item.title,
					timestamp: item.start,
					description: item.description,
					allDay: item.allDay,
					url: app_root,
					location: props.info.name
				}


				if (item.recurring) {

					let days = matchWeekdays(item.recurring.daysOfWeek);

					if (days.length) {
						obj = {
							...obj,
							repeating: {
								freq: item.recurring.freq,
								until: item.recurring.endRecur,
								byDay: days,
								wkst: 'SU'
							}
						}
					} else {
						obj = {
							...obj,
							repeating: {
								freq: item.recurring.freq,
								until: item.recurring.endRecur,
								wkst: 'SU'
							}
						}
					}
				}

				if (obj.start && obj.end) {
					return obj;
				}
			}
		});

		setCalendarData(arr.filter(item => item));

	}, [exportEvents]);

	if (servicesData) {


		const icalEvents = exportEvents.length && ical({
			domain: 'sebbo.net',
			prodId: '//superman-industries.com//ical-generator//EN',
			events: calendarData
		})

		const events = services && services.filter(item => filterServices.indexOf(item.extendedProps.event_type) < 0);

		return (
			<React.Fragment>
				<div className='grid grid--reverse-mobile'>
					<div className='cell cell--3--large cell--12--small'>
						<div className='services-wrap'>
							{makeServices(servicesLegend)}
							{exportEvents &&
								<div>
									<p style={{ fontSize: '0.875rem', marginTop: '1rem', marginBottom: '0' }}>If you wish to subscribe automatically to any calendar, please check the appropriate event types and choose your calendar type.</p>
									<div className="calendar-wrapper__actions">
										{/* <CsvDownload data={calendarData} className="btn btn--primary" /> */}
										<a
											href={`data:text/calendar;charset=utf-8,${encodeURIComponent(
												icalEvents
											)}`}
											download="events.ics"
											className="btn btn--primary"
										>
											{`Subscribe`} <img src={icon_google} alt="Subscribe Google" />
										</a>
										<a
											href={`data:text/calendar;charset=utf-8,${encodeURIComponent(
												icalEvents
											)}`}
											download="events.ics"
											className="btn btn--primary"
										>
											{`Subscribe`} <img src={icon_apple} alt="Subscribe Apple" />
										</a>
									</div>
								</div>
							}
						</div>
					</div>
					<div className='cell cell--9--large cell--12--small'>
						<div className='calendar-wrapper '>
							{services && (
								<FullCalendar
									ref={calendar}
									timeZone='London/Europe'
									header={{
										center:
											'dayGridMonth,listMonth',
									}}
									eventTimeFormat={{
										hour: 'numeric',
										minute: '2-digit',
										meridiem: 'short'
									}}
									eventOrder="startSort"
									defaultView='dayGridMonth'
									plugins={[
										dayGridPlugin,
										listPlugin,
										rrulePlugin
									]}
									displayEventTime={true}
									events={events}
									eventClick={(info) => {
										showEventModal(info);
									}}
									eventRender={(info) => {

										/* sets events for export when there aren't any */
										calendar && calendar.current && getCalendarEvents();

										if (info.el.querySelector('.fc-list-item-time')) {

											if (info.event.allDay) {
												info.el.querySelector('.fc-list-item-time').innerHTML = '<div>All Day Event</div>'
											} else {
												info.el.querySelector('.fc-list-item-time').innerHTML = `<div>${info.event.extendedProps.startTime}</div><div>${info.event.extendedProps.endTime}</div>`;
											}
										}

										if (info.event.backgroundColor) {
											info.el.style.borderColor = info.event.backgroundColor;
										}
									}}
								/>
							)}
						</div>
					</div>
				</div>
				{eventContent && <CalendarModal
					item={eventContent}
					isActive={modalActive}
					handleClose={() => { setModalActive(false) }}
				/>}
			</React.Fragment>
		)
	} else {
		return null;
	}
}