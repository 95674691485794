import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

function CookiesWrapper() {
	return (
		<div>
			<CookieConsent location='bottom' buttonText='Ok'>
				This website uses cookies to enhance the user experience.{' '}
				<Link to={`/legals/privacy-policy`} className='color-accent'>
					Privacy policy
				</Link>
			</CookieConsent>
		</div>
	);
}

export default CookiesWrapper;
