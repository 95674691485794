import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

/* function MapContainer(props) {
	const coords = {
		lat: props.lat,
		lng: props.long
	};

	return (
		<div className='map'>
			<Map zoom={8} initialCenter={{ lat: 47.444, lng: -122.176 }} />
		</div>
	);
}

export default GoogleApiWrapper({
	apiKey: ''
})(MapContainer); */

export class MapContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stores: [
				{ lat: 47.49855629475769, lng: -122.14184416996333 },
				{ latitude: 47.359423, longitude: -122.021071 },
				{ latitude: 47.2052192687988, longitude: -121.988426208496 },
				{ latitude: 47.6307081, longitude: -122.1434325 },
				{ latitude: 47.3084488, longitude: -122.2140121 },
				{ latitude: 47.5524695, longitude: -122.0425407 }
			]
		};
	}

	displayMarkers = () => {
		return this.state.stores.map((store, index) => {
			return (
				<Marker
					key={index}
					id={index}
					position={{
						lat: store.latitude,
						lng: store.longitude
					}}
				/>
			);
		});
	};

	render() {
		const mapStyles = {
			width: '100%',
			height: '100%'
		};

		return (
			<Map
				google={this.props.google}
				zoom={14}
				style={mapStyles}
				initialCenter={{ lat: this.props.lat, lng: this.props.long }}
			>
				<Marker position={{ lat: this.props.lat, lng: this.props.long }} />
			</Map>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyAFNtxECx2G3n4fn5UTUaXYm6Tl3lVYoRw&'
})(MapContainer);
