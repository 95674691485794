import React from 'react';

// my components
import WhatsOns from '../../components/whats_ons/whats_ons';
import Seo from '../../components/seo/seo';

const WhatIsOnIndex = (props) => {
	return (
		<div className='view--whats-on'>
			<Seo
				pageSeo={{
					seo_title: `What's On | ${props.churchName}`,
					seo_description: `Check out what's on`,
				}}
			/>
			<div className='view__content view__body'>
				<div className='layout--main'>
					<section
						className='section--whats-on section--offset section--primary'
						data-aos='fade-in'
					>
						<div className='grid grid--end'>
							<div className='cell cell--11--large'>
								<WhatsOns
									maxWhatIsOns={999}
									user={props.currentUser}
									seeMore={false}
								/>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
};

export default WhatIsOnIndex;
//if redux use below as export
/*
function mapStateToProps(state, props) {
  return {
    currentUser: state.currentUser
  };
};

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(addCurrentUserAction, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
*/
