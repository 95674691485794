import React from 'react';
//https://www.npmjs.com/package/react-html-parser
import ReactHtmlParser from 'react-html-parser';

// my components

// my helpers
import { pageRootUpdate } from '../../helpers/api_routes';
import { mail } from '../../helpers/common_constants';
import LazyLoad from 'react-lazyload';

const ArticleBookings = props => {
	const item = props.item;

	return (
		<div className='layout--main'>
			<article className='article article--bookings'>
				<header className='article__header' data-aos='fade-in'>
					<LazyLoad>
						<img src={`${pageRootUpdate}${item.image}`} alt={item.title} />
					</LazyLoad>
				</header>

				<div className='grid grid--center'>
					<div className='cell cell--6--large cell--12--small'>
						<h1 data-aos='fade-in'>{item.title}</h1>
						{item.background_image && (
							<header className='article__header floorplan'>
								<LazyLoad>
									<img
										src={`${pageRootUpdate}${item.background_image}`}
										alt='Floorplan'
									/>
								</LazyLoad>
							</header>
						)}
						<div className='article__main'>
							
							{/*<p> {item.description} </p>*/}
							<div
								data-aos='fade-in'
								className='article__content font--medium gray'
							>
								{ReactHtmlParser(item.content)}
							</div>
							<a
								data-aos='fade-in'
								className='btn btn--primary'
								href={`mailto:${mail}?subject=Venue Hire - ${item.title}`}
							>
								Book Now
							</a>
						</div>
					</div>
					<div className='cell cell--6--large cell--12--small'>
						<div className='bookings-info'>
							{ReactHtmlParser(item.specialization)}
						</div>
					</div>
				</div>
			</article>
		</div>
	);
};

export default ArticleBookings;
