import React, { Component } from 'react';

// redux
/*
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { addCurrentUserAction } from '../../actions/current_user'
*/

// my components

// my helpers
import { eventsUrl, bookingsUrl, pageRootUrl } from '../helpers/api_routes';
import { t } from '../locales/translations';
import { mail } from '../helpers/common_constants';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import LazyLoad from 'react-lazyload';

// for fetching data from server
const axios = require('axios');

class BookingsIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: this.props.currentUser,
			events: [],
			bookings: [],
			venuHires: [],
			awaitingServer: false,
			errors: null,
			disabled: true,
			mail: mail
		};
	}

	componentDidMount() {
		this.getBookings();
		this.getVenuHires();
		// start loader
		this.setState({ awaitingServer: true });

		var that = this;
		axios
			.get(eventsUrl.index)
			.then(function(response) {
				that.props.setSectionsLoaded && that.props.setSectionsLoaded("venues");
				// backend should take care off pagination
				that.setState({
					events: response.data.events.items,
					awaitingServer: false
				});
			})
			.catch(function(error) {
				that.setState({ errors: t('sorry_error'), awaitingServer: false });
			});
		this.getBookings();
	}

	getVenuHires() {
		var that = this;
		axios
			.get(bookingsUrl.texts)
			.then(function(response) {
				// backend should take care off pagination
				that.setState({
					venuHires: response.data.venue_hire_text.items
				});
			})
			.catch(function(error) {
				that.setState({ errors: t('sorry_error') });
			});
		this.getBookings();
	}

	getBookings() {
		var that = this;
		axios
			.get(bookingsUrl.index)
			.then(function(response) {
				// backend should take care off pagination
				that.setState({
					bookings: response.data.venue_hires.items,
					awaitingServer: false
				});
			})
			.catch(function(error) {
				that.setState({ errors: t('sorry_error'), awaitingServer: false });
			});
	}

	makeBookings() {
		if (this.state.bookings) {
			return (
				this.state.bookings &&
				this.state.bookings.map((item, i) => {
					return (
						<div
							key={i}
							className='card card--big center-inblock'
							data-aos='fade-in'
						>
							<div className='card__img'>
								<LazyLoad>
									<img src={`${pageRootUrl}/${item.image}`} alt={item.title} />
								</LazyLoad>
							</div>
							<div className='card__body'>
								<div className='text--xlarge card__title'>{item.title}</div>
								<div className='card__copy'>{item.description}</div>
								<div className='text--slarge color-accent medium'>
									{item.price}
								</div>
							</div>
							<div className='card__btn'>
								<Link
									to={`/booking/${item.slug}`}
									className='btn btn--primary btn--full'
								>
									{t('find_out_more')}
								</Link>
							</div>
						</div>
					);
				})
			);
		} else {
			return <React.Fragment />;
		}
	}
	render() {
		return (
			<div>
				<section className='section--padded--tb--2x section--primary'>
					<header className='section--header'>
						<div className='layout--main'>
							<div className='grid'>
								<div className='cell cell--8--large cell--12--small'>
									<div className='section__body' data-aos='fade-in'>
										<h1 className='white'>{t('bookings.title')}</h1>
										<div className='text--large white'>
											{this.state.venuHires &&
												ReactHtmlParser(this.state.venuHires.content)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</header>

					<div className='layout--main'>
						<div className='grid grid--center'>
							<div className='cell cell--12--large cell--12--small'>
								<div className='grid--4--large grid--2--medium'>
									{this.makeBookings()}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default BookingsIndex;
//if redux use below as export
/*
function mapStateToProps(state, props) {
  return {
    currentUser: state.currentUser
  };
};

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(addCurrentUserAction, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
*/
