import React from 'react';
import { t } from '../../locales/translations';
import { pageRootUrl } from '../../helpers/api_routes';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const SectionCommunity = props => {
	const makeSection = () => {
		return (
			<section className={`section--community section--padded--tb`}>
				<div className='layout--main'>
					<div className='community white'>
						<div className='community__content' data-aos='fade-in'>
							<div className='card--circle'>
								<h1>{props.item.title}</h1>
								<p>{props.item.description}</p>
								<Link
									className='btn btn--primary'
									item={props.item}
									to={`/our-communities/${props.item.slug}`}
								>
									{t('find_out_more')}
								</Link>
							</div>
						</div>
						<div className='community__img' data-aos='fade-in'>
							<LazyLoad>
								<img
									src={`${pageRootUrl}/${props.item.image}`}
									alt={props.item.title}
								/>
							</LazyLoad>
						</div>
					</div>
				</div>
			</section>
		);
	};

	return <React.Fragment>{makeSection()}</React.Fragment>;
};

export default SectionCommunity;
