import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { contactUrl } from '../../helpers/api_routes';
import { t } from '../../locales/translations';
import {
	sm_facebook,
	sm_twitter,
	sm_linkedin,
	sm_trip_advisor,
	sm_youtube,
	sm_instagram
} from '../../helpers/image_paths';

// for fetching data from server
const axios = require('axios');

const SocialLinks = props => {
	const [items, setItems] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		axios
			.get(contactUrl.show)
			.then(function(response) {
				// backend should take care off pagination
				setItems(response.data.contact_infos.item);
			})
			.catch(function(error) {
				setErrors(t('sorry_error'));
			});
	}, []);

	let socialNetworks =
		items.length > 0 &&
		items.map(item => [
			{
				title: 'Facebook',
				link: item.facebook,
				image: sm_facebook
			},
			{
				title: 'Twitter',
				link: item.tweeter,
				image: sm_twitter
			},
			{
				title: 'Linkedin',
				link: item.linkedin,
				image: sm_linkedin
			},
			{
				title: 'TripAdvisor',
				link: item.tripadviser,
				image: sm_trip_advisor
			},
			{
				title: 'YouTube',
				link: item.youtube,
				image: sm_youtube
			},
			{
				title: 'Instagram',
				link: item.instagram,
				image: sm_instagram
			}
		]);

	const makeItems = () => {
		var i = 0;
		return (
			socialNetworks.length > 0 &&
			socialNetworks[0].map(item => {
				i++;

				return (
					<React.Fragment key={i}>
						{item.link && item.link.length > 0 && (
							<a
								href={item.link}
								className='social-link'
								target='_blank'
								rel='noopener noreferrer'
							>
								{item.image ? (
									<img src={item.image} alt={`${item.link}`} />
								) : null}
								{props.show_name && item.title ? (
									<span>{item.title}</span>
								) : null}
							</a>
						)}
					</React.Fragment>
				);
			})
		);
	};
	return <React.Fragment>{makeItems()}</React.Fragment>;
};

export default SocialLinks;
