/* eslint-disable no-useless-escape */
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { t } from '../../locales/translations';
import ReactHtmlParser from 'react-html-parser';
import { useState } from 'react';
import { booksUrl, appsUrl, podcastsUrl, pageRootUpdate, videosUrl } from '../../helpers/api_routes';
import { useEffect } from 'react';
import { app_store, google_play, ch_publishing } from '../../helpers/image_paths';
import moment from 'moment';
import { vimeoIframe } from '../../helpers/common_constants';
const axios = require('axios');

const TabsWrapper = (props) => {
	const [ books, setBooks ] = useState([]);
	const [ apps, setApps ] = useState([]);
	const [ podcasts, setPodcasts ] = useState([]);
	const [ videos, setVideos ] = useState([]);

	// eslint-disable-next-line no-unused-vars
	const [ error, setError ] = useState('');

	const makeTabsList = () => {
		return (
			props.items.length > 0 &&
			props.items.map((item, i) => {
				return <Tab key={i}>{item.title}</Tab>;
			})
		);
	};

	useEffect(
		() => {
			getBooks();
			getApps();
			getPodcasts();
			getVideos();
		},
		[ props ]
	);

	const getBooks = () => {
		axios
			.get(booksUrl.index)
			.then(function(response) {
				props.setSectionsLoaded && props.setSectionsLoaded("learning");
				// backend should take care off pagination
				setBooks(response.data.books.items);
			})
			.catch(function(error) {
				setError(t('sorry_error'));
			});
	};

	const getApps = () => {
		axios
			.get(appsUrl.index)
			.then(function(response) {
				// backend should take care off pagination
				setApps(response.data.app_items.items);
			})
			.catch(function(error) {
				setError(t('sorry_error'));
			});
	};

	const getPodcasts = () => {
		axios
			.get(podcastsUrl.index)
			.then(function(response) {
				// backend should take care off pagination
				setPodcasts(response.data.podcasts.items);
			})
			.catch(function(error) {
				setError(t('sorry_error'));
			});
	};

	const getVideos = () => {
		axios
			.get(videosUrl.index)
			.then(function(response) {
				// backend should take care off pagination
				setVideos(response.data.learning_videos.items);
			})
			.catch(function(error) {
				setError(t('sorry_error'));
			});
	};

	const makeBooks = () => {
		return (
			books.length > 0 &&
			books.map((item, i) => {
				const makeCustomBooks = <a href={`${item.url}`} target="_blank"><img src={`${pageRootUpdate}/${item.image}`} alt={item.title} /></a>
				return (
					<React.Fragment key={i}>
						{item.url === "#" ? ReactHtmlParser(item.amazon_code) : makeCustomBooks  }
					</React.Fragment>
				);
			})
		);
	};

	const makePodcasts = () => {
		return (
			podcasts.length > 0 &&
			podcasts.map((item, i) => {
				return (
					<div className="card card--small card--podcast" key={i}>
						<div className="card__img">
						<img src={`${pageRootUpdate}/${item.image}`} alt={item.title} loading="lazy" />
						</div>
						<div className="card__body">
							<h3 className="base-font-family">{item.title}</h3>
							<p className="card__description">{item.description}</p>
						</div>
						<a href={item.url} target="_blank" rel="noopener noreferrer" className="overlay-link">
							{t('find_out_more')}
						</a>
					</div>
				);
			})
		);
	};

	const makeApps = () => {
		return (
			apps.length > 0 &&
			apps.map((item, i) => {
				return (
					<div className="card card--small card--transparent card--left" key={i}>
						<div className="card__img">
						<img src={`${pageRootUpdate}/${item.image}`} alt={item.title} loading="lazy" />
						</div>
						<div className="card__body">
							<h3 className="base-font-family">{item.title}</h3>
							<p className="card__description">{item.description}</p>
						</div>
						<div className="card__stores">
							<a
								href={item.url_android}
								target="_blank"
								rel="noopener noreferrer"
								className="link--store"
							>
									<img src={google_play} alt="Google Play" />
							</a>
							<a href={item.url_iphone} target="_blank" rel="noopener noreferrer" className="link--store">
									<img src={app_store} alt="App Store" />
							</a>
						</div>
					</div>
				);
			})
		);
	};

	function getYtVideoId(url) {
		var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		var match = url.match(regExp);
		if (match && match[2].length === 11) {
			return match[2];
		} else {
		}
		//error
	}

	function getVimeoVideoId(url) {
		var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
		var match = url.match(regExp);
		if (match && match[5]) {
			return match[5];
		}
	}

	function makeVideos(items) {
		if (items && items.length > 0) {
			return items.map((item) => {
				return (
					<div key={item.id} className="card card--compact card--video card-padding" data-aos="fade-in">
						<div className="card__body">
							<p className="card__copy">{item.description}</p>
							{/* <div className='date-time-info date-time-info--small color-accent'>
								<div>
									{time_start !== 'Invalid date' && <span> {time_start} </span>}
									{time_end !== 'Invalid date' && <span> {time_end} </span>}
								</div>
								<div>{item.time}</div>
							</div> */}
							<div className="card__title font--heading bold">{item.title}</div>
							<p className="color-accent">
								{moment(item.created_at, 'YYYY-MM-DD').format('Do MMMM YYYY')}
							</p>
							<div className="card__description">{ReactHtmlParser(item.description)}</div>
						</div>
						<div className="card__btn">
							<div className="card__img">
									{item.url.includes('youtube') ? (
										<iframe
											src={`https://www.youtube.com/embed/${getYtVideoId(item.url)}`}
											alt={item.title}
											title={item.title}
										/>
									) : (
										<iframe
											src={`${vimeoIframe}${getVimeoVideoId(item.url)}`}
											alt={item.title}
											title={item.title}
										/>
									)}
							</div>
						</div>
					</div>
				);
			});
		} else {
			return null;
		}
	}

	if (props.items.length > 0) {
		return (
			<React.Fragment>
				<Tabs defaultIndex={0} defaultFocus={true}>
					<TabList>
						<div>{makeTabsList()}</div>
					</TabList>
					<React.Fragment>
						<TabPanel>
							<div className="tab-content tab-content--books">
								<div className="tab-grid tab-grid--books">{makeBooks()}</div>
								{/* <div className="tab-content__row">
									<a
										href="https://www.chpublishing.co.uk/category/christian-books/books"
										target="_blank"
										rel="noopener noreferrer"
										className="btn btn--primary"
									>
										{t('learning_and_faith.books.link')}
									</a>
									<div className="tab-content__logo">
										<img src={ch_publishing} alt="Church House Publishing" />
									</div>
								</div> */}
							</div>
						</TabPanel>
						<TabPanel>
							<div className="tab-content">
								<div className="tab-grid">{makePodcasts()}</div>
							</div>
						</TabPanel>
						<TabPanel>
							<div className="tab-content">
								<div className="tab-grid">{makeApps()}</div>
								<div className="tab-content__row">
									<a
										href="https://www.chpublishing.co.uk/apps"
										target="_blank"
										rel="noopener noreferrer"
										className="btn btn--primary"
									>
										{t('learning_and_faith.apps.link')}
									</a>
									<div className="tab-content__logo">
										<img src={ch_publishing} alt="Church House Publishing" />
									</div>
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							<div className="tab-content">
								<div className="tab-grid">{makeVideos(videos)}</div>
							</div>
						</TabPanel>
					</React.Fragment>
				</Tabs>
			</React.Fragment>
		);
	} else {
		return null
	}
	
};

export default TabsWrapper;
