import React, { Component } from 'react';
//https://www.npmjs.com/package/react-html-parser
import ReactHtmlParser from 'react-html-parser';
// redux

// my helpers
import { whatsOnsUrl, pageRootUpdate, contactUrl, pageRootUrl } from '../../helpers/api_routes';
import { t } from '../../locales/translations';
import Breadcrumbs from '../../components/breadcrumbs';
import Seo from '../../components/seo/seo';

import LazyLoad from 'react-lazyload';
import SectionLoader from '../../components/loaders/section_loader';
import { ifString } from '../../helpers/if_string';
import ErrorMsg from '../../components/error';

// for fetching data from server
const axios = require('axios');

class WhatIsOnShow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: this.props.currentUser,
			whatIsOn: null,
			id: this.props.match.params && this.props.match.params.id ? this.props.match.params.id : null,
			awaitingServer: true,
			errors: null,
			contacts: []
		};
	}

	getContacts() {
		// get events
		var that = this;
		axios
			.get(contactUrl.show)
			.then(function (response) {
				that.setState({
					contacts: response.data.contact_infos.item[0]
				});
			})
			.catch(function (error) {
				that.setState({ error: t('sorry_error') });
			});
	}

	componentDidMount() {
		this.getContacts();
		// start loader
		this.setState({ awaitingServer: true });

		var that = this;
		axios
			.get(`${whatsOnsUrl.show}${this.state.id}.json`)
			.then(function (response) {
				// backend should take care off pagination
				if (response.data.what_is_on.item) {
					that.setState({
						whatIsOn: response.data.what_is_on.item,
						awaitingServer: false
					});
				}
			})
			.catch(function (error) {
				that.setState({
					errors: error.toString(),
					awaitingServer: false
				});
			});
	}

	render() {
		var item = this.state.whatIsOn;
		if (item) {
			let image = `${pageRootUrl}${item.image}`;
			return (
				<React.Fragment>
					<Seo
						pageSeo={{
							seo_title: ifString(
								item.seo_title,
								item.title
							),
							seo_description: ifString(
								item.seo_description,
								item.description
							),
							seo_image: image,
							canonical: item.slug
						}}
					/>
					{item ? (
						<React.Fragment key={item.id}>
							<article className="layout--main">
								<Breadcrumbs
									breadcrumbs_route={'/what-is-ons'}
									breadcrumbs_text={`What's on`}
									breadcrumbs_page={item.title}
								/>
								<header className="article__header" data-aos="fade-in">
									<LazyLoad height="1000">
										<img src={`${pageRootUpdate}${item.image}`} alt={item.title} />
									</LazyLoad>
								</header>

								<div className="grid grid--center">
									<div className="cell cell--12--large cell--12--small">
										<div className="article__body">
											<div className="article__main" data-aos="fade-in">
												<h1> {item.title} </h1>
												{/*<p> {item.description} </p>*/}
												<div className="article__content font--medium gray margin--dobule margin--80--l">
													{ReactHtmlParser(item.content)}{' '}
												</div>
												{this.state.contacts.email && (
													<a
														className="btn btn--primary"
														data-aos="fade-in"
														href={`mailto:${this.state.contacts.email}${item.email
															? `,${item.email}`
															: ''}?subject=${item.title}`}
													>
														Get in Touch
													</a>
												)}
											</div>
										</div>
									</div>
								</div>
							</article>
						</React.Fragment>
					) : null}

					<div className="layout--main">
						{' '}
						<p className="gray center-inblock font--medium"> {this.state.errors} </p>
					</div>
				</React.Fragment>
			);
		} else {
			if (this.state.errors) {
				return <ErrorMsg error={this.state.errors} />
			} else {
				return <SectionLoader />;
			}
		}
	}
}

export default WhatIsOnShow;
