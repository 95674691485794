import React from 'react';
//https://www.npmjs.com/package/react-html-parser
import ReactHtmlParser from 'react-html-parser';
import { pageRootUpdate, pageRootUrl } from '../../helpers/api_routes';
import Breadcrumbs from '../breadcrumbs';
import Seo from '../seo/seo';
import LazyLoad from 'react-lazyload';
import {ifString} from '../../helpers/if_string';
import SectionLoader from '../loaders/section_loader';

const ArticleCommunity = (props) => {
	// eslint-disable-next-line no-unused-vars
	let item = props.item;
	let image = `${pageRootUrl}${item.image}`;

	const makeItems = (item) => {
		return (
			<React.Fragment>
				<Seo pageSeo={{
					seo_title: ifString(item.seo_title, item.title),
					seo_description: ifString(item.seo_description, item.description),
					seo_image: image,
					canonical: item.slug
				}} />
				<div className='layout--main'>
					<Breadcrumbs
						breadcrumbs_route={'/our-community'}
						breadcrumbs_text={`Our community`}
						breadcrumbs_page={item.id}
					/>
					<article className='article article--news'>
						<header className='article__header' data-aos='fade-in'>
							<LazyLoad>
								<img src={`${pageRootUpdate}${item.image}`} alt={item.title} />
							</LazyLoad>
						</header>
						<div className='grid grid--center'>
							<div className='cell cell--12--large cell--12--small'>
								<div className='article__body article__body--news' data-aos='fade-in'>
									<div className='article__main'>
										<h1>{item.title}</h1>
										{/*<p> {item.description} </p>*/}
										<div className='article__content font--medium gray'>
											{ReactHtmlParser(item && item.content)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</article>
				</div>
			</React.Fragment>
		);
	};

	if (item) {
		return <React.Fragment>{item && <div>{makeItems(item)}</div>}</React.Fragment>;
	} else {
		return <SectionLoader />;
	}
};

export default ArticleCommunity;
