import React, { Component } from 'react';

// my components
import ArticleAbout from '../components/articles/article_about';

import { t } from '../locales/translations';
import { aboutsUrl } from '../helpers/api_routes';
import Seo from '../components/seo/seo';
import { handleHashLink } from '../helpers/common_constants'

// for fetching data from server
const axios = require('axios');

class About extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: this.props.currentUser,
			awaitingServer: true,
			abouts: [],
			sections: ["history", "team", "venues", "gallery"],
			sectionsLoaded: [],
			pageLoaded: false
		};

		this.setSectionsLoaded = this.setSectionsLoaded.bind(this)
	}

	setSectionsLoaded(section) {
		if (!this.state.sectionsLoaded.includes(section)) {
			this.setState(prevState => {
				return { sectionsLoaded: [...prevState.sectionsLoaded, section] }
			})
		}
	}

	componentDidMount() {
		this.setState({ awaitingServer: false });

		var that = this;

		axios
			.get(aboutsUrl.show)
			.then(function (response) {
				// backend should take care off pagination

				that.setState(prevState => {
					return { sectionsLoaded: [...prevState.sectionsLoaded, "history"] }
				})

				that.setState({
					abouts: response.data.about_groups.about_page,
					awaitingServer: false,
				});

			})
			.catch(function (error) {
				that.setState({ errors: t('sorry_error'), awaitingServer: false });
			});
	}

	componentDidUpdate() {
		if (!this.state.pageLoaded) {
			if (this.state.sectionsLoaded.includes("history" && "gallery" && "team")) {
				this.setState({ pageLoaded: true });
			}
		}

		if (this.state.pageLoaded) {
			handleHashLink();
		}
	}

	render() {

		let item = {
			title: t('history.title'),
			content: t('history.content')
		};

		return (
			<div className="view--about" style={{ visibility: this.state.pageLoaded ? 'visible' : 'hidden' }}>
				<Seo pageSeo={{
					seo_title: `About | ${this.props.churchName}`,
					seo_description: 'About the Church'
				}} />
				<div className="view__body">
					<ArticleAbout item={item} abouts={this.state.abouts} setSectionsLoaded={this.setSectionsLoaded} />
				</div>
			</div>
		);
	}
}

export default About;
