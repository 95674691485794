import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

//import Tweets from "../components/tweets/tweets";
import Cta from '../components/ctas/cta';

import ReactHtmlParser from 'react-html-parser';

// my helpers
import { newsItemsUrl, missionUrl, callToActionsUrl } from '../helpers/api_routes';
import { t } from '../locales/translations';
import { safeguarding } from '../helpers/image_paths';

import Notification from '../components/notifications/notification';

import Slider from 'react-slick';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import WhatsOnsHome from '../components/whats_ons/whats_ons_home';
import SectionLoader from '../components/loaders/section_loader';

// for fetching data from server
const axios = require('axios');

const Home = (props) => {
	// eslint-disable-next-line
	const [ maxEvents ] = useState(6);
	const [ maxCta ] = useState(5);
	const [ events, setEvents ] = useState(null);
	const [ cta, setCta ] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [ mission, setMission ] = useState(null);
	const [ errorsEvents, setErrorEvents ] = useState(null);
	// eslint-disable-next-line no-unused-vars

	const getEvents = () => {
		// get events
		axios
			.get(newsItemsUrl.index)
			.then(function(response) {
				setEvents(response.data.news_items.items);
			})
			.catch(function(error) {
				setErrorEvents(t('sorry_error'));
			});
	};

	const getMission = () => {
		axios
			.get(missionUrl.mission)
			.then(function(response) {
				setMission(response.data.about_groups.home_page);
			})
			.catch(function(error) {
				setErrorEvents(t('sorry_error'));
			});
	};

	const getCtas = () => {
		axios
			.get(callToActionsUrl.index)
			.then(function(response) {
				setCta(response.data.call_to_actions.items);
			})
			.catch(function(error) {
				setErrorEvents(t('sorry_error'));
			});
	};

	useEffect(() => {
		getCtas();
		getEvents();
		getMission();
	}, []);

	function makeCta(items) {
		var i = 0;
		if (items.length > 0) {
			return items.slice(0, maxCta).map((item, index) => {
				i++;
				return <Cta item={item} key={i} id={i} />;
			});
		} else {
			return null;
		}
	}

	function makeEvents(items) {
		if (items && items.length > 0) {
			return items.slice(0, maxEvents).map((item) => {
				return (
					<div key={item.id} className='event'>
						<div className='event__date font- medium'>{moment(item.start_at).format('DD.MM.YYYY')}</div>
						<div className='event__title font--medium medium'>{item.title}</div>
						<div className='event__desc margin--double'>{item.description}</div>
						<Link to={`/news-item/${item.slug}`} className='btn btn--transparent'>
							{t('read_more')}
						</Link>
					</div>
				);
			});
		} else {
			return null;
		}
	}

	function makeSection(items) {
		if (items && mission) {
			return items.map((item, id) => {
				return (
					<section key={id} className='section--margin--large'>
						<div className='layout--main'>
							<div className='safeguarding' data-aos='fade-in'>
								<div className='safeguarding__item'>
									<React.Fragment>
										<h1 className=''>{mission[0].title}</h1>
										<div className='font--medium gray margin--double'>
											{ReactHtmlParser(mission[0].content)}
										</div>
										<Link to='/about' className='btn btn--primary margin--double'>
											{item.buttonText}
										</Link>
										<div className='safeguarding__img'>
											<img
												src={`https://cityoflondonchurch.com/${mission[0].image}`}
												alt={mission[0].title}
											/>
										</div>
									</React.Fragment>
								</div>

								<div className='safeguarding__main'>
									<div className='' data-aos='fade-in'>
										<div className='section__body section__body--margin'>
											<h1>{item.title}</h1>
											<div className='safeguarding__img'>
												<LazyLoadImage src={safeguarding} alt={item.title} effect='blur' />
											</div>
											<p className='text'>{item.description}</p>
										</div>

										{item.buttonText && (
											<Link to={item.buttonUrl} className='btn btn--primary'>
												{item.buttonText}
											</Link>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
				);
			});
		} else {
			return null;
		}
	}

	var settings = {
		dots: false,
		infinite: false,
		speed: 220,
		slidesToShow: 2,
		arrows: true,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
		//slidesToScroll: 1
	};

	return (
		<div className='view--home'>
			<div className='view__body'>
				<Notification src={`${newsItemsUrl.index}`} />

				{/*What is on*/}
				<WhatsOnsHome maxWhatIsOns={20} user={props.currentUser} seeMore={true} slider={true} />

				{/*CTA*/}
				{cta ? (
					<section className='section--cta section--padded--large' data-aos='fade-in'>
						<div className='layout--main'>
							<div className='grid grid--end'>
								<div className='cell cell--12--large cell--12--small'>
									<div className='cta__wrap'>{makeCta([ ...cta ])}</div>
								</div>
							</div>
						</div>
					</section>
				) : (
					<SectionLoader />
				)}

				{/*Events*/}
				{events ? (
					<section className='section--events section--padded--large'>
						<div className='layout--main' data-aos='fade-in'>
							<div className='cell cell--6--large'>
								<h1 className='margin--double'>{t('news.title')}</h1>
								<p className='font--medium gray margin--80--l' />
							</div>

							<div className='cell cell--12--large'>
								<div className='slider--cards'>
									<Slider {...settings}>{makeEvents(events)}</Slider>
									<p>{errorsEvents}</p>
								</div>
							</div>
						</div>
					</section>
				) : (
					<SectionLoader />
				)}

				{/*Section with bg*/}
				{mission ? (
					<React.Fragment>
						{makeSection([
							{
								title: t('safeguarding.title'),
								description: t('safeguarding.description'),
								widthLarge: '6',
								widthSmall: '12',
								section: 'info--bg',
								buttonText: t('find_out_more'),
								buttonUrl: '/safeguarding'
							}
						])}
					</React.Fragment>
				) : (
					<SectionLoader />
				)}
			</div>
		</div>
	);
};

export default Home;
