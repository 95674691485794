import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
//https://www.npmjs.com/package/react-html-parser redux
import Headroom from "react-headroom";
import GetInvolved from "./buttons/get_involved";
import SocialLinks from "./social_links/social_links";
import Subnav from "./subnav";
import SearchForm from "./forms/search_form";
import { useState } from "react";
import { useEffect } from "react";
import { navLogo } from "../helpers/image_paths.js";
import OpeningTimesButton from "./opening_times_button";
import { pageRootUrl } from "../helpers/api_routes";

const MainNavigation = props => {
  const [burgerState, setBurgerState] = useState(false);
  const [pathname, setPathname] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setPathname(location.pathname);
    }
  }, [location]);

  const handleBurger = () => {
    setBurgerState(false);
  };

  useEffect(() => {
    if (window.innerWidth < 1024) {
      if (burgerState) {
        document.querySelector("html").classList.add("burger-is-active");
      } else {
        document.querySelector("html").classList.remove("burger-is-active");
      }
    }
  }, [burgerState]);

  const makeLinks = items => {
    var i = 0;
    return items.map(item => {
      let link = item.isRoot ? "/" : `/${item.slug}`;
      let image = item.image ? item.image : null;
      let text = item.text ? item.text : null;
      let logoText = item.logoText ? item.logoText : null;
      //let highlight = item.highlight ? item.highlight : null
      i++;

      let isLogo = logoText ? "nav__logo" : "nav__link";
      /* let isHighlight = highlight ? ' nav__link--highlight' : '' */
      let isActive =
        pathname && pathname.includes("/" + item.slug) ? " active" : "";

      return (
        <React.Fragment key={i}>
          <Link
            to={link}
            className={`${isLogo}${isActive}`}
            onClick={() => handleBurger()}>
            {/* LINK can be an image*/}
            {image ? <img src={image} alt={`${link}`} /> : null}

            {/* LINK can be an textual logo */}
            {logoText ? (
              <div>
                <span>{`${logoText}`}</span>
              </div>
            ) : null}

            {/* LINK can be some text*/}
            {text ? <span>{`${text}`}</span> : null}
          </Link>
        </React.Fragment>
      );
    });
  };

  /* const makeSocialLinks = (items) => {
		var i = 0;
		return items.map((item) => {
			i++;

			return (
				<React.Fragment key={i}>
					<a href={item.slug} className="nav__link" target="_blank" rel="noopener noreferrer">
						{item.image ? <img src={item.image} alt={`${item.slug}`} /> : null}
						{item.text ? <span>{item.text}</span> : null}
					</a>
				</React.Fragment>
			);
		});
	}; */

  const makeWorkingDays = item => {
    return (
      item &&
      item.length > 0 &&
      item.map((item, i) => {
        return (
          <React.Fragment key={i}>
            {item.monday && (
              <div key={i}>
                <span>Mon:</span>
                {item.monday}
              </div>
            )}
            {item.tuesday && (
              <div key={i}>
                <span>Tue:</span>
                {item.tuesday}
              </div>
            )}
            {item.wednesday && (
              <div key={i}>
                <span>Wed:</span>
                {item.wednesday}
              </div>
            )}
            {item.thursday && (
              <div key={i}>
                <span>Thu:</span>
                {item.thursday}
              </div>
            )}
            {item.friday && (
              <div key={i}>
                <span>Fri:</span>
                {item.friday}
              </div>
            )}
            {item.saturday && (
              <div key={i}>
                <span>Sat:</span>
                {item.saturday}
              </div>
            )}
            {item.sunday && (
              <div key={i}>
                <span>Sun:</span>
                {item.sunday}
              </div>
            )}
          </React.Fragment>
        );
      })
    );
  };

  return (
    <React.Fragment>
      <GetInvolved />
      <Headroom>
        <div className="nav nav--desktop">
          <div className="layout--main">
            <div className="nav__top">
              <div>
                <div className="nav__top-wrap">
                  <div className="logo-subtitle">
                    {props.info.is_working ? (
                      <div>
                        <p>Opening times:</p>
                        <div>{makeWorkingDays(props.info.working_days)}</div>
                      </div>
                    ) : (
                      <div>
                        {props.info.non_working_days &&
                          props.info.non_working_days.map((item, i) => (
                            <a
                              href={`${item.link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="color-accent"
                              key={i}
                              onClick={() => handleBurger()}>
                              {item.title}
                            </a>
                          ))}
                      </div>
                    )}
                  </div>
                  {props.info.pastoral_service_text && (
                    <a
                      href={`mailto:${props.info.pastoral_service_email}`}
                      className="btn btn--primary"
                      onClick={() => handleBurger()}>
                      {props.info.pastoral_service_text}
                    </a>
                  )}
                  <Link
                    to="/submit-a-prayer"
                    className="btn btn--primary"
                    onClick={() => handleBurger()}>
                    Submit a Prayer
                  </Link>
                  {makeLinks([
                    {
                      slug: "contact",
                      text: "Contact",
                    },
                  ])}
                  <div className="social-icons">
                    <span>follow us</span>
                    <SocialLinks show_name={false} />
                  </div>
                  <SearchForm
                    searchTerm={props.searchTerm}
                    setSearchTerm={props.setSearchTerm}
                    searchHandler={props.searchHandler}
                    searchResults={props.searchResults}
                    setSearchResults={props.setSearchResults}
                  />
                </div>
              </div>
            </div>

            <div className="nav__wrap">
              <div className="nav__logo--wrap">
                <Link
                  to="/"
                  className={`nav__logo`}
                  onClick={() => handleBurger()}>
                  <img
                    src={
                      props.info.image
                        ? `${pageRootUrl}/${props.info.image}`
                        : navLogo
                    }
                    alt="Digital Church Platform"
                  />
                </Link>
              </div>
              <div className="nav__links medium">
                {makeLinks([
                  {
                    slug: "what-is-ons",
                    text: "What's on",
                    highlight: true,
                  },
                  {
                    slug: "about",
                    text: "About",
                  },
                  {
                    slug: "news-items",
                    text: "News",
                  },
                  {
                    slug: "faith",
                    text: "Faith",
                  },
                  {
                    slug: "our-community",
                    text: "Our Community",
                  },
                  {
                    slug: "safeguarding",
                    text: "Safeguarding",
                  },
                  {
                    slug: "giving",
                    text: "Giving",
                  },
                ])}
              </div>
            </div>
          </div>
          {pathname && pathname.includes("/about") && <Subnav subnav="about" info={props.info} />}
          {pathname && pathname.includes("/faith") && <Subnav subnav="faith" info={props.info} />}
          {pathname && pathname.includes("/news-items") && (
            <Subnav subnav="news-items" info={props.info} />
          )}
          {pathname && pathname.includes("/external-news") && (
            <Subnav subnav="external-news" info={props.info} />
          )}
          {pathname && pathname.includes("/church-news") && (
            <Subnav subnav="church-news" info={props.info} />
          )}
        </div>

        <div
          className={`nav nav--mobile ${burgerState ? "burger-active" : ""}`}>
          <div className="layout--main">
            <div className="nav__wrap">
              <div className="nav__logo--wrap">
                <Link
                  to="/"
                  className={`nav__logo`}
                  onClick={() => handleBurger()}>
                  <img
                    src={
                      props.info.image
                        ? `${pageRootUrl}/${props.info.image}`
                        : navLogo
                    }
                    alt="Digital Church Platform"
                  />
                </Link>
                <div>
                  {props.info.is_working ? (
                    <OpeningTimesButton
                      openingTimesState={props.openingTimesState}
                      setOpeningTimesState={props.setOpeningTimesState}
                    />
                  ) : (
                    <div>
                      {props.info.non_working_days &&
                        props.info.non_working_days.map((item, i) => (
                          <a
                            href={`${item.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="color-accent"
                            key={i}
                            onClick={() => handleBurger()}>
                            {item.title}
                          </a>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="nav-burger">
                <div
                  className="burger-icon"
                  onClick={() => {
                    setBurgerState(!burgerState);
                  }}
                />
                <div className="nav-burger__main">
                  <div className="layout--main">
                    {props.info.pastoral_service_text && (
                      <a
                        href={`mailto:${props.info.pastoral_service_email}`}
                        className="btn btn--primary important-link"
                        onClick={() => handleBurger()}>
                        {props.info.pastoral_service_text}
                      </a>
                    )}
                    <div className="nav__links medium">
                      {makeLinks([
                        {
                          slug: "submit-a-prayer",
                          text: "Submit a Prayer",
                          highlight: true,
                        },
                        {
                          slug: "what-is-ons",
                          text: "What's on",
                        },
                        {
                          slug: "about",
                          text: "About",
                        },
                        {
                          slug: "news-items",
                          text: "News",
                        },
                        {
                          slug: "faith",
                          text: "Faith",
                        },
                        {
                          slug: "our-community",
                          text: "Our Community",
                        },
                        {
                          slug: "safeguarding",
                          text: "Safeguarding",
                        },
                        {
                          slug: "giving",
                          text: "Giving",
                        },
                        {
                          slug: "contact",
                          text: "Contact",
                        },
                      ])}
                    </div>
                    <div className="nav__social">
                      <div className="social-icons">
                        <span>follow us</span>
                        <SocialLinks show_name={false} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.subnav === "about" && <Subnav subnav="about" info={props.info} />}
          {props.subnav === "faith" && <Subnav subnav="faith" info={props.info} />}
          {props.subnav === "news-items" && <Subnav subnav="news-items" info={props.info} />}
          {props.subnav === "external-news" && (
            <Subnav subnav="external-news" info={props.info} />
          )}
          {props.subnav === "church-news" && <Subnav subnav="church-news" info={props.info} />}
        </div>
      </Headroom>
      <button
        className="back-to-top"
        onClick={() => {
          window.scrollTo(0, 0);
        }}>
        &#8593;
      </button>
    </React.Fragment>
  );
};

export default MainNavigation;
