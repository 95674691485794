import React, { Component } from 'react';
import axios from 'axios';
import { contactMessagesUrl } from '../../helpers/api_routes';

export default class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			full_name: '',
			email: '',
			message: '',
			status: ''
		};
	}

	changeHandler = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	submitHandler = e => {
		e.preventDefault();
		axios
			.post(contactMessagesUrl.validate, this.state)
			.then(response => {
				axios
					.post(contactMessagesUrl.messages, this.state)
					.then(res => {
						this.setState({
							status: res.data.status
						});
					})
					.catch(error => {
						console.log(error);
					});
			})
			.catch(error => {
				console.log(error);
			});
	};

	render() {
		const { full_name, email, message } = this.state;
		return (
			<form
				action='submit'
				className='form form--border'
				onSubmit={this.submitHandler}
			>
				<fieldset className='form__fieldset'>
					<label htmlFor=''>Full Name</label>
					<input
						type='text'
						className='input input--text'
						name='full_name'
						value={full_name}
						onChange={this.changeHandler}
					/>
				</fieldset>
				<fieldset className='form__fieldset'>
					<label htmlFor=''>Email</label>
					<input
						type='email'
						className='input input--text'
						name='email'
						value={email}
						onChange={this.changeHandler}
					/>
				</fieldset>
				<fieldset className='form__fieldset'>
					<label htmlFor=''>Content</label>
					<textarea
						name='message'
						cols='30'
						rows='10'
						className='textarea'
						value={message}
						onChange={this.changeHandler}
					></textarea>
				</fieldset>
				<input
					type='submit'
					className={`btn btn--full ${
						full_name.length > 0 && email.length > 0 && message.length > 0
							? 'btn--primary'
							: 'btn--disabled'
					}`}
					value='Send'
				/>
				{this.state.status.length > 0 && (
					<div
						className={`form__status ${
							this.state.status.includes('message sent!')
								? 'form__status--ok'
								: 'form__status--error'
						}`}
					>
						<span>{this.state.status}</span>
					</div>
				)}
			</form>
		);
	}
}
