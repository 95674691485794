// To add new lang import json here 
// To use t('key') will return string of that key
import {HR} from './hr.json';
import {EN} from './en.json';

export function t(keys) {
//  console.log("+++",keys)

  // global.language is from local.storage
  let language = localStorage.churchLanguage; 
  if (!language) {
    language = "EN";
    localStorage.setItem('churchLanguage', language);
  }

  const allLanguages = {HR, EN};
  let keysAsArray = keys.split(".");

  // translatedString is a string that user can see
  let translatedString = digInObj(allLanguages[language], keysAsArray);

  return translatedString;
};

// this function will repeat it self until the string is fount
// example for t('reg.welcome') digInObj will run two times until
// "Welcome to umye string is found"
function digInObj(obj, keysAsArray) {
  var currentKey = keysAsArray.shift();
  let remainingKeys = keysAsArray;

  if (remainingKeys.length > 0) {
    // if the result of this iteration is obj
    // and there are more remainingKeys to seek in 
    // repeat!
    return digInObj(obj[currentKey], remainingKeys)
  } else {
    // when there are no more remainingKeys the 
    // result should be a string
    return obj[currentKey]
  }
}
