import React from "react";
import Seo from "./seo/seo";

export default function ErrorMsg(props) {
	return (<section className="error-msg">
		<div className="layout--main center-inblock">
			<Seo pageSeo={{
				seo_title: 'Request failed ' + '#' + Math.random().toString(36).substring(7),
				seo_description: props.error + window.location.href,
			}} />

			<h1>{props.error} at</h1>
			<p className="text--large color-accent">{window.location.href}</p>
		</div>
	</section>)
}