import React, { useState, useEffect } from 'react';
import PrayerForm from '../components/forms/prayer_form';
import Seo from '../components/seo/seo';

const SubmitPrayer = (props) => {
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		setIsActive(true);
	}, []);

	return (
		<div className='view'>
			<Seo
				pageSeo={{
					seo_title: `Submit a Prayer | ${props.churchName}`,
					seo_description: 'Submit Your prayers to our prayer wall',
				}}
			/>
			<div className={`${isActive ? 'loader loader--loaded' : 'loader'}`}>
				<div className='view__body'>
					<div className='layout--main'>
						<header className='section--header center-inblock'>
							<h1 className='black'>Submit a Prayer</h1>
							<div className='grid grid--center'>
								<div className='cell--5--large cell--12--small'>
									<p className='gray text--large'>
										Please fill in the form below to submit
										your prayers for our online prayer wall.
									</p>
								</div>
							</div>
						</header>
						<section className=''>
							<div className='grid grid--center'>
								<div className='cell cell--6--large'>
									<PrayerForm />
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubmitPrayer;
