import React from 'react';
import moment from 'moment-timezone';

// redux
/*
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { addCurrentUserAction } from '../../actions/current_user'
*/

// my components
import Tweets from '../../components/tweets/tweets';

// my helpers
import { pageRootUpdate, externalNewsUrl } from '../../helpers/api_routes';
import { t } from '../../locales/translations';

// for fetching data from server
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import Seo from '../../components/seo/seo';

const NewsChurchIndex = (props) => {
	const [ newsItems, setNewsItems ] = useState([]);
	const [ errors, setErrors ] = useState(null);

	useEffect(() => {
		axios
			.get(externalNewsUrl.index)
			.then(function(response) {
				// backend should take care off pagination
				setNewsItems(response.data.external_news_items.items);
			})
			.catch(function(error) {
				setErrors(t('sorry_error'));
			});
	}, []);


	function makeNewsItems(items) {
		let list = items && items.filter((obj) => obj.name === "churchofengland").map((obj) => obj.news_items);
		list = list.length > 0 && [].concat(...list);

		if (list && items.length > 0) {
			return list.map((item, index) => (
				<div key={item.id} className='card card--horizontal card--news' data-aos="fade-up">
					<div className='card__img'>
						<LazyLoad>
							{item.image ? <img src={`${pageRootUpdate}${item.image}`} alt='news' /> : <div class="empty-image"></div>}
						</LazyLoad>
					</div>
					<div className='card__body black'>
						<p className='color-primary'>
							{item.publish_at && <span>{moment(item.publish_at, 'YYYY-MM-DD').format('DD.MM.YYYY')}</span>}
						</p>
						<div className='text--xlarge card__title'>{item.title}</div>
						<p className='card__copy black'>{item.description}</p>
					</div>
					<div className='card__btn'>
						<a href={item.url} target='_blank' className='overlay-link' rel='noopener noreferrer'>
							Read more
						</a>
					</div>
				</div>
			));
		} else {
			return <h2 className='white no-margin base-font-family'>List of news is empty.</h2>;
		}
	}

	return (
		<div className='view--news'>
			<Seo
				pageSeo={{
					seo_title: `News from the Church of England | ${props.churchName}`,
					seo_description: 'Checkout the latest news from the Church of England'
				}}
			/>
			<div className='layout--main'>
				<div className='grid'>
					<div className='cell cell--12--large cell--12--small'>
						<section className='section--offset section--primary section--padded--tb'>
							<div className='list'>{makeNewsItems(newsItems)}</div>
							<p>{errors}</p>
						</section>
					</div>
				</div>

				<section className='tweets-section'>
					<Tweets handles={['JustinWelby']} />
				</section>
			</div>
		</div>
	);
};

export default NewsChurchIndex;
