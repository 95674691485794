import React from "react";
import { clock } from "../helpers/image_paths";

const OpeningTimesButton = ({ openingTimesState, setOpeningTimesState }) => {
  
  return (
      <button
        className="btn btn--secondary opening-times-button"
        onClick={() => setOpeningTimesState(!openingTimesState)}>
        <img src={clock} /> Opening times
      </button>
  );
};

export default OpeningTimesButton;
