import React, { useEffect } from 'react';
import Seo from '../../components/seo/seo';
import queryString from 'query-string';

const Index = (props) => {

	useEffect(() => {
		if (props.location.search) {
			let str = queryString.parse(props.location.search).term;
			if (str) props.searchHandler(str);
		}
	}, [props])

  const makeResults = (items) => {
    let url = '';
    return items && items.map((item, index) => {
      if (item && item.resource !== 'template-page') {
        if (!item.url) {
          if (item.resource === 'what-is-on') {
            url = `/${item.resource}/${item.slug}`;
          } else if (item.resource === 'news-item') {
            url = `/${item.resource}/${item.slug}`;
          } else if (item.resource === 'our-community') {
            url = `/our-communities/${item.slug}`;
          } else if (item.resource === 'book') {
            url = '/faith#learning';
          } else if (item.resource === 'contact-info') {
            url = `/contact`;
          } else if (item.resource === 'donation') {
            url = `/donations/1`;
          } else if (item.resource === 'event') {
            url = `/faith#events`;
          } else if (item.resource === 'call-to-action') {
            url = `/`;
          } else if (item.resource === 'event-type') {
            url = `/faith`;
          } else if (item.resource === 'online-video') {
            url = `/faith#videos`;
          } else if (item.resource === 'podcast') {
            url = `/faith#learning`;
          } else if (item.resource === 'service') {
            url = `/faith`;
          } else if (item.resource === 'venue-hire') {
            url = `/about#venues`;
          } else if (item.resource === 'safeguarding-officer') {
            url = `/safeguarding`;
          } else {
            url = `/${item.resource}`;
          }
        } else {
          url = item.url;
        }

        return (
          <div className="cell cell--12--large cell--12--small" key={index}>
            <a href={url}>
              <div className="card--search" key={index}>
                <h3 className="color-primary">{item.title}</h3>
                {item.description && <p>{item.description}</p>}
              </div>
            </a>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  if (props.results) {
    return (
      <div className="view">
        <Seo pageSeo={{ seo_title: `Search | ${props.churchName}`, seo_description: `Search results` }} />
        <div className="layout--main">
          <header className="search-header">
            <h1>
              Search results
  					</h1>
            <div>{props.results.items && <span>{props.results.items.length} results</span>}</div>
          </header>
          <div className="grid">{makeResults(props.results.items)}</div>
            {!props.results.items && <p>No results.</p>}
            {props.results.items && !props.results.items.length && <p>No results.</p>}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Index;
