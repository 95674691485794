import React from "react";
import Slider from "react-slick";
import AwaitingServerWrapper from "./wrappers/awaiting_server_wrapper";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { useState } from "react";
import { t } from "../locales/translations";
import axios from "axios";
import { useEffect } from "react";
import { onlineVideos } from "../helpers/api_routes";
import LazyLoad from "react-lazyload";
import { getYtId, vimeoIframe } from "../helpers/common_constants";
import reactHtmlParser from "react-html-parser";
import moment from "moment";

const VideoSlider = props => {
  const [awaitingServer, setAwaitingServer] = useState(false);
  const [items, setItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");

  useEffect(() => {
    // start loader
    setAwaitingServer(true);
    // get what is ons
    axios
      .get(onlineVideos.index)
      .then(function (response) {
        props.setSectionsLoaded && props.setSectionsLoaded("videos");
        // backend should take care off pagination
        setItems(response.data.online_videos.items);
        setAwaitingServer(false);
      })
      .catch(function (error) {
        setError(t("sorry_error"));
        setAwaitingServer(false);
      });
  }, []);

  function makeItems(items) {
    if (items && items.length > 0) {
      return items.map(item => {
        return (
          <div
            key={item.id}
            className="card card--compact card--video card-padding"
            data-aos="fade-in">
            <div className="card__body">
							<p className="card__date color-primary">
                {moment(item.publish_at, "YYYY-MM-DD").format("Do MMMM YYYY")}
              </p>
              {/* <div className='date-time-info date-time-info--small color-accent'>
								<div>
									{time_start !== 'Invalid date' && <span> {time_start} </span>}
									{time_end !== 'Invalid date' && <span> {time_end} </span>}
								</div>
								<div>{item.time}</div>
							</div> */}
              <div className="card__title font--heading bold">{item.title}</div>
              <div className="card__description">
                {reactHtmlParser(item.description)}
              </div>
            </div>
            <div className="card__btn">
              <div className="card__img">
                <LazyLoad>
                  {!item.video_key.includes("watch") ? (
                    <iframe
                      src={`${vimeoIframe}${item.video_key}`}
                      alt={item.title}
                      title={item.title}
                    />
                  ) : (
                    <iframe
                      width="560"
                      height="auto"
                      src={`https://www.youtube.com/embed/${getYtId(item.video_key)}`}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                  )}
                </LazyLoad>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 220,
    slidesToShow: 3,
    arrows: true,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    //slidesToScroll: 1
  };

  return (
    <div className="layout--main">
      <LazyLoadComponent height={1000}>
        <AwaitingServerWrapper awaitingServer={awaitingServer}>
          <div className="slider--whats-on slider--video">
            <Slider {...settings}>{makeItems(items)}</Slider>
          </div>

          {/*<p> {t('what_is_on.check_all')} </p>*/}
          <Link to="/what-is-ons" className="link--section"></Link>
        </AwaitingServerWrapper>
      </LazyLoadComponent>
    </div>
  );
};

export default VideoSlider;
