/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { submitedLikesUrl } from '../helpers/api_routes';
import { heart_icon, heart_icon_active } from '../helpers/image_paths';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie'

const Prayer = (props) => {
  const [like, setLike] = useState(false);
  const [likeNumber, setLikeNumber] = useState(props.item.like_num > 0 ? props.item.like_num : 1)
  const [cookies, setCookie, removeCookie] = useCookies(['name']);

  let val = `prayer_${props.item.id}`

  /* curl -X PUT -H "Accept: application/json" --data 'submited_prayer[action]=like' https://cityoflondonchurch.com/api/v2/[:id]/submited_prayers/[:prayer_id] */

  useEffect(() => {
    if (Object.keys(cookies).indexOf(val) > 0) {
      setLike(true)
    }
  }, [cookies])

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .put(
        `${submitedLikesUrl.likes}/${props.item.id}`, {
          submited_prayer: {
            action: like ? 'unlike' : 'like'
          }
        })
      .then((response) => {
        setLike(!like)
        like ? removeCookie(`prayer_${props.item.id}`) : setCookie(`prayer_${props.item.id}`, '1')
        !like ? setLikeNumber(likeNumber + 1) : setLikeNumber(likeNumber < 1 ?  0 : likeNumber - 1)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      key={`${props.page - 1}_${props.item.id}`}
      className='card card--compact card-padding card--prayer'
      data-aos='fade-in'
    >
      <div className='card__body'>
        {props.item.is_anonymous ? (
          <div>
            <strong>Name:</strong> Anonymous
          </div>
        ) : (
          <div>
            <strong>Name:</strong>{' '}
            {props.item.first_name !== 'Anonymous'
                ? props.item.first_name
                : ''}{' '}
                {props.item.last_name !== 'Anonymous'
                    ? props.item.last_name
                    : 'Anonymous'}
                  </div>
        )}
        <div>
          <strong>Location:</strong> {props.item.city}
        </div>
        {props.item.prayer_for && (
          <div>
            <strong>Prayer for:</strong> {props.item.prayer_for}
          </div>
        )}
        <p className='card__description'>{props.item.content}</p>
      </div>
      <button
        className='like-btn'
        onClick={(e) => {
          setLike(!like);
          submitHandler(e);
        }}
      >
        <div className='like-btn__icon'>
          <img
            src={like ? heart_icon_active : heart_icon}
            alt='Like button'
          />
        </div>
        {likeNumber} Prayers
      </button>
    </div>
  );
};

export default Prayer;
