import React from 'react';
import SectionCommunity from '../../components/sections/section_community';
import { useState } from 'react';
import { useEffect } from 'react';
import { ourCommunitiesUrl } from '../../helpers/api_routes';
import TabsPartnersWrapper from '../../components/tabs/tabs_partners';
import Seo from '../../components/seo/seo';

// for fetching data from server
const axios = require('axios');

const OurCommunityIndex = (props) => {
	const [items, setItems] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [error, setError] = useState(null);

	useEffect(() => {
		axios
			.get(ourCommunitiesUrl.index)
			.then(function (response) {
				// backend should take care off pagination
				setItems(response.data.our_communities.items);
			})
			.catch(function (error) {
				setError(error);
			});
	}, []);

	return (
		<React.Fragment>
			<Seo
				pageSeo={{
					seo_title: `Our Community | ${props.churchName}`,
					seo_description: 'Join our worshipping community'
				}}
			/>
			<React.Fragment>
				{items.map((item, i) => {
					if (item) {
						return (
							<SectionCommunity item={item} key={i} index={i} />
						);
					} else {
						return null;
					}
				})}
				<section className='section--padded'>
					<div className='layout--main'>
						<div className='margin--double'>
							<h2>Partners</h2>
						</div>
					</div>
					<TabsPartnersWrapper />
				</section>
			</React.Fragment>
		</React.Fragment>
	);
};

export default OurCommunityIndex;
