// libraries
import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

// helpers
//import { addCurrentUserToStore } from './helpers/current_user_from_local_storage';

// pages
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';

import BookingsShow from './pages/bookings/bookings_show';

import WhatIsOnIndex from './pages/what_is_ons/what_is_on_index';
import WhatIsOnShow from './pages/what_is_ons/what_is_on_show';
import NewsItemsIndex from './pages/news_items/news_items_index';
import NewsItemShow from './pages/news_items/news_item_show';

import NewsExternalIndex from './pages/news_external/news_external_index';

import ServiceShow from './pages/services/service_show';
import Safeguarding from './pages/safeguardings/safeguarding';

import AOS from 'aos';
import 'aos/dist/aos.css';

//import Login from './pages/accounts/login';
//import ForgotPassword from './pages/accounts/forgot_password';

// styles
import './App.scss';
import Legals from './pages/legals/legal_show';
import OurCommunityIndex from './pages/our_community/our_community_index';
import OurCommunityShow from './pages/our_community/our_community_show';
import NewsChurchIndex from './pages/news_church/news_church_index';
import Donations from './pages/donations/donations';
import SubmitPrayer from './pages/submit_prayer';
import PrayersIndex from './pages/prayers/prayers_index';
import axios from 'axios';

import ReactGA from 'react-ga';
import { contactUrl, app_root, pageRootUrl } from './helpers/api_routes';
import { t } from './locales/translations';
import { SeoProvider } from './components/wrappers/seo_wrapper';

import CookiesWrapper from './components/cookies';

import SearchIndex from './pages/searches';
import { useState } from 'react';
import { defaultLogo, headerImg, navLogo } from './helpers/image_paths';
import Seo from './components/seo/seo';
import ShareButtons from './components/share_buttons';
import MainNavigation from './components/main_navigation';
import Footer from './components/footer';
import NotFound from './pages/not_found';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import OpeningTimesDropdown from './components/opening_times_dropdown';

ReactGA.initialize('UA-157877526-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = (props) => {
  const [churchInfo, setChurchInfo] = useState();
  const [seo, setSeo] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [openingTimesState, setOpeningTimesState] = useState(false);

  const history = useHistory();

  const searchHandler = (term) => {
    let data = {
      term: term,
      page: 1,
      per_page: 99,
      format: 'json',
      church_id: 1,
      results: null,
    };

    if (term && term.length) {
      axios
        .post('https://cityoflondonchurch.com/api/v2/1/search', data)
        .then((res) => {
          setSearchResults(res.data.results);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    let str = queryString.stringify({ term: searchTerm });
    if (searchResults) history.push(`/search?${str}`);
  }, [searchResults]);

  const getScript = (src) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
  };

  useEffect(() => {
    if (
      !document.querySelector(
        'script[src="//script.crazyegg.com/pages/scripts/0094/1165.js"]'
      )
    ) {
      getScript('//script.crazyegg.com/pages/scripts/0094/1165.js');
    }

    AOS.init({
      duration: 300,
      offset: -50,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getChurchInfo();
  }, [props]);

  const getChurchInfo = () => {
    axios
      .get(contactUrl.show)
      .then(function (response) {
        let item = response.data.contact_infos.item[0];
        setChurchInfo(item);
        setSeo({
          seo_title: item.name,
          seo_description:
            item.seo_description.length > 0
              ? item.seo_description
              : t('description'),
          seo_keywords: item.seo_keywords,
          image: app_root + headerImg,
          mailchimp_code: item.mailchimp_code,
          name: item.name,
        });
      })
      .catch(function (error) {
        setError(t('sorry_error'));
      });
  };

  //let currentUser = currentUser;
  const handleUpdate = () => {
    window.scrollTo(0, 0);
    return null;
  };

  useEffect(() => {
    let splash = document.querySelector('.splash-screen');
    if (seo && splash) {
      splash.style.opacity = '0';
      setTimeout(() => {
        splash.remove();
      }, 220);
    }
  }, [seo]);

  if (churchInfo && seo) {
    const routeProps = (props) => {
      return { ...props, churchName: seo.name, seo: seo };
    };

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{seo.name}</title>
          <meta
            name='description'
            content={ReactHtmlParser(seo.seo_description)}
          />
          <meta
            property='og:description'
            content={ReactHtmlParser(seo.seo_description)}
          />
          <meta property='og:title' content={ReactHtmlParser(seo.seo_title)} />
          <meta name='robots' content='noindex' />
          <meta name='keywords' content={seo.seo_keywords} />
          <meta property='og:image:width' content='400' />
          <meta property='og:image:height' content='300' />
          <meta property='og:type' content='website' />
          <meta property='og:image' content={seo.image} />
          <meta name='image' content={seo.image} />
          <meta property='twitter:card' content='summary_large_image' />
          <meta
            property='twitter:title'
            content={ReactHtmlParser(seo.seo_title)}
          />
          <meta
            property='twitter:description'
            content={ReactHtmlParser(seo.seo_description)}
          />
          <meta property='twitter:image' content={seo.image} />
        </Helmet>
        <div className='splash-screen'>
          <p></p>
        </div>
        {openingTimesState && <OpeningTimesDropdown openingTimesState={openingTimesState} setOpeningTimesState={setOpeningTimesState} working_days={churchInfo.working_days} />}
        <SeoProvider seo={seo}>
          <Seo item={seo} />
          <ShareButtons />
          <CookiesWrapper />
          <Route component={handleUpdate} />
          <div className='main'>
            <MainNavigation
              info={churchInfo}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchHandler={searchHandler}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              openingTimesState={openingTimesState}
              setOpeningTimesState={setOpeningTimesState}
            />
            <Switch>
              <Route path='/' exact component={Home} />
              <Route
                path='/about'
                render={(props) => <About {...routeProps(props)} />}
              />
              <Route
                path='/contact'
                render={(props) => <Contact {...routeProps(props)} />}
              />
              <Route
                path='/what-is-ons'
                render={(props) => <WhatIsOnIndex {...routeProps(props)} />}
              />
              <Route path='/what-is-on/:id' component={WhatIsOnShow} />
              <Route path='/booking/:id' component={BookingsShow} />
              <Route
                path='/news-items'
                render={(props) => <NewsItemsIndex {...routeProps(props)} />}
              />
              <Route path='/news-item/:id' component={NewsItemShow} />
              <Route
                path='/external-news'
                render={(props) => <NewsExternalIndex {...routeProps(props)} />}
              />
              <Route
                path='/church-news'
                render={(props) => <NewsChurchIndex {...routeProps(props)} />}
              />
              <Route
                path='/legals/:id'
                render={(props) => <Legals {...routeProps(props)} />}
              />
              <Route
                path='/giving'
                render={(props) => <Donations {...routeProps(props)} />}
              />
              <Route
                path='/safeguarding'
                render={(props) => <Safeguarding {...routeProps(props)} />}
              />
              <Route
                path='/faith'
                render={(props) => (
                  <ServiceShow {...routeProps(props)} info={churchInfo} />
                )}
              />
              <Route
                path='/our-community'
                render={(props) => <OurCommunityIndex {...routeProps(props)} />}
              />
              <Route path='/our-communities/:id' component={OurCommunityShow} />
              <Route
                path='/submit-a-prayer'
                render={(props) => <SubmitPrayer {...routeProps(props)} />}
              />
              <Route
                path='/prayer-wall'
                render={(props) => <PrayersIndex {...routeProps(props)} />}
              />
              <Route
                path='/search'
                render={(props) => (
                  <SearchIndex
                    {...routeProps(props)}
                    results={searchResults}
                    searchHandler={searchHandler}
                  />
                )}
              />
              <Route path='*' component={NotFound} status={404} />
            </Switch>
            <Footer />
          </div>
        </SeoProvider>
      </React.Fragment>
    );
  } else {
    return (
      <div className='splash-screen'>
        <img src={defaultLogo} height={64} />
      </div>
    );
  }
};

export default App;
