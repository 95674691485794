import React from 'react';
import { get_involved } from '../../helpers/image_paths';
import { Link } from 'react-router-dom';

const GetInvolved = (props) => {
	return (
		<div>
			<Link
				to={`/faith#videos`}
				scroll={(el) => {
					setTimeout(() => {
						el.scrollIntoView(true);
						window.scrollBy({ top: -100 });
					}, 100);
				}}
				className='btn btn--fixed'
			>
				<img src={get_involved} alt='Get Involved' />
			</Link>
		</div>
	);
};

GetInvolved.propTypes = {};

export default GetInvolved;
