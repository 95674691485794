import React, { useState, useEffect } from "react";

// redux
/*
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { addCurrentUserAction } from '../../actions/current_user'
*/

// my components
import AwaitingServerWrapper from "../wrappers/awaiting_server_wrapper";

// my helpers
import { tweetsUrl } from "../../helpers/api_routes";
import { t } from "../../locales/translations";

import moment from "moment";

import ReactHtmlParser from 'react-html-parser';

// for fetching data from server
const axios = require("axios");

const Tweets = props => {
  // eslint-disable-next-line
  const [tweets, setTweets] = useState(null);
  const [awaitingServerTweets, setAwaitingServerTweets] = useState(false);
  const [errorsTweets, setErrorsTweets] = useState(null);

  useEffect(() => {
    // start loader
    setAwaitingServerTweets(true);
    // get what is ons
    axios
      .get(tweetsUrl.index)
      .then(function (response) {
        // backend should take care off pagination
        setTweets(response.data.tweet_groups);
        setAwaitingServerTweets(false);
      })
      .catch(function (error) {
        setErrorsTweets(t("sorry_error"));
        setAwaitingServerTweets(false);
      });
  }, [props]);

  function makeTweets(items) {
    let keys = Object.keys(items).filter(item => item !== "title");


    if (props.handles) {
      keys = keys.filter(item => props.handles.find(object => object === item))
    }

    if (props.ignore) {
      keys = keys.filter(item => !props.ignore.find(object => object === item))
    }

    return keys.map(key => {
      return (
        <div className="tweets__subsection">
          <a href={`https://twitter.com/${key}`} target="_blank" rel='noopener noreferrer' class="tweets__title">@{key}</a>
          <div className="tweets-section__wrap">
            {items[key]
              .map(tweet => {
                return (
                  <div className="tweet" key={tweet.id} data-aos="fade-up">
                    <div className="tweet__name">@{tweet.screen_name}</div>
                    <div className="tweet__date">
                      {moment(tweet.tweeted_at).format("DD.MM.YYYY. HH:mm")}
                    </div>
                    <div className="tweet__content">{ReactHtmlParser(tweet.content)}</div>
                    <div className="tweet__hashtags">{tweet.hashtags}</div>
                  </div>
                );
              })
              .reverse()}
          </div>
        </div>
      );
    });
  }

  return (
    <AwaitingServerWrapper awaitingServer={awaitingServerTweets}>
      {tweets && makeTweets(tweets)}
      {/*<p> {t('what_is_on.check_all')} </p>*/}
      {errorsTweets && <p> {errorsTweets} </p>}
    </AwaitingServerWrapper>
  );
};

export default Tweets;
