import React, { useState, useEffect } from 'react';
import { t } from '../locales/translations';
import { contactUrl } from '../helpers/api_routes';
import MapContainer from '../components/maps/map';
import Team from '../components/teams/team';
import ReactHtmlParser from 'react-html-parser';
import ContactForm from '../components/forms/contact_form';
import Seo from '../components/seo/seo';

// for fetching data from server
const axios = require('axios');

const Contact = (props) => {
	const [contacts, setContacts] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [error, setError] = useState('');

	const getSafeguardings = () => {
		// get events
		axios
			.get(contactUrl.show)
			.then(function (response) {
				setContacts(response.data.contact_infos.item);
			})
			.catch(function (error) {
				setError(t('sorry_error'));
			});
	};

	useEffect(() => {
		getSafeguardings();
	}, []);

	const makeContactInfo = () => {
		return (
			contacts.length > 0 &&
			contacts.map((obj, i) => {
				return (
					<React.Fragment key={i}>
						<div className='margin--double'>
							<div>Address: {obj.address}</div>
							<div>Telephone: {obj.phone}</div>
							<div>
								Email: &nbsp;
								<a
									href={`mailto:${obj.email}`}
									className='color-blue bold'
									target='_blank'
									rel='noopener noreferrer'
								>
									{obj.email}
								</a>
							</div>
						</div>
						<h1>Where to find us</h1>
						<div className='map-wrap'>
							<MapContainer
								long={obj.longitude}
								lat={obj.latitude}
							/>
						</div>
						{obj.office_hours && (
							<React.Fragment>
								<h1>Office hours</h1>
								<div className='font--medium'>
									{ReactHtmlParser(obj.office_hours)}
								</div>
							</React.Fragment>
						)}
					</React.Fragment>
				);
			})
		);
	};

	function makeWorkingDays(item) {
		return (
			item.length > 0 &&
			item.map((item, i) => {
				return (
					<React.Fragment>
						{item.monday && (
							<div>
								<span>Mon:</span> {item.monday}
							</div>
						)}
						{item.tuesday && (
							<div>
								<span>Tue:</span> {item.tuesday}
							</div>
						)}
						{item.wednesday && (
							<div>
								<span>Wed:</span> {item.wednesday}
							</div>
						)}
						{item.thursday && (
							<div>
								<span>Thu:</span> {item.thursday}
							</div>
						)}
						{item.friday && (
							<div>
								<span>Fri:</span> {item.friday}
							</div>
						)}
						{item.saturday && (
							<div>
								<span>Sat:</span> {item.saturday}
							</div>
						)}
						{item.sunday && (
							<div>
								<span>Sun:</span> {item.sunday}
							</div>
						)}
					</React.Fragment>
				);
			})
		);
	}

	return (
		<div className='view--contact'>
			<Seo
				pageSeo={{
					seo_title: `Contact | ${props.churchName}`,
					seo_description: 'Contact information',
				}}
			/>
			<div className='view__body'>
				<div className='layout--main'>
					{/* contact form section */}
					<section className='section--padded--tb'>
						<div className='contact-form'>
							<div className='contact-form__main'>
								<h1>Contact Us</h1>
								<ContactForm />
							</div>
							<div className='contact-form__info'>
								{makeContactInfo()}
								{contacts[0] && contacts[0].is_working && (
									<section className='contact-opening-times' id="opening-times">
										<p>Opening times</p>
										{contacts[0] &&
											contacts[0].working_days &&
											makeWorkingDays(contacts[0].working_days)}
									</section>
								)}
							</div>
						</div>
					</section>
				</div>
				<Team dark={true} />
			</div>
		</div>
	);
};

export default Contact;
