import React from 'react';
import { useEffect } from 'react';
import { t } from '../locales/translations';
import { useState } from 'react';
import { submitedPrayersListUrl } from '../helpers/api_routes';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import Prayer from './prayer';

const SubmitedPrayers = (props) => {
	const [items, setItems] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [awaitingServerItems, setAwaitingServerItems] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [errors, setErrors] = useState('');
	const [page, setPage] = useState(2);

	function makeItems(items) {
		if (items) {
			if (props.showAll) {
				return items.map((item, i) => {
					return (
						<Prayer item={item} page={page} key={i} id={i}/>
					);
				});
			} else {
				return items.slice(0, 3).map((item, i) => {
					return (
						<Prayer item={item} page={page} key={i} id={i}/>
					);
				});
			}
		} else {
			return <React.Fragment />;
		}
	}

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	function getData(i) {
		// start loader
		// get what is ons
		axios
			.get(
				props.showAll
					? `${submitedPrayersListUrl.index}?per_page=10&page=1`
					: submitedPrayersListUrl.index
			)
			.then(function (response) {
				props.setSectionsLoaded && props.setSectionsLoaded("prayers");
				// backend should take care off pagination
				setItems(response.data.submited_prayers.items);
				setAwaitingServerItems(false);
			})
			.catch(function (error) {
				setErrors(t('sorry_error'));
				setAwaitingServerItems(false);
			});
	}

	function getMoreData(i) {
		// start loader
		// get what is ons
		axios
			.get(
				props.showAll
					? `${submitedPrayersListUrl.index}?per_page=10&page=${i}`
					: submitedPrayersListUrl.index
			)
			.then(function (response) {
				// backend should take care off pagination
				setItems(items.concat(response.data.submited_prayers.items));
				if (response.data.submited_prayers.items.length > 0) {
					setPage(page + 1);
				}
				setAwaitingServerItems(false);
			})
			.catch(function (error) {
				setErrors(t('sorry_error'));
				setAwaitingServerItems(false);
			});
	}

	if (props.showAll) {
		return (
			<React.Fragment>
				<InfiniteScroll
					pageStart={0}
					initialLoad={false}
					loadMore={() => {
						getMoreData(page);
					}}
					hasMore={true || false}
				>
					<div className='layout--main'>
						<div className='grid--3--large grid--2--medium'>
							{makeItems(items)}
						</div>
					</div>
				</InfiniteScroll>
			</React.Fragment>
		);
	} else {
		return (
			<div className='layout--main'>
				<div className='grid--3--large grid--2--medium'>
					{makeItems(items)}
				</div>
			</div>
		);
	}
};

export default SubmitedPrayers;
