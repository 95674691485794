import React from 'react';
import {
	FacebookShareButton,
	//LinkedinShareButton,
	TwitterShareButton,
	FacebookIcon,
	TwitterIcon,
	LinkedinShareButton,
	LinkedinIcon,
	EmailShareButton,
	EmailIcon,
	//LinkedinIcon,
	//EmailShareButton,
	//EmailIcon
} from 'react-share';
import { useContext } from 'react';
import { SeoContext } from './wrappers/seo_wrapper';

const ShareButtons = () => {
	let seoInfo = useContext(SeoContext);

	if (seoInfo.data) {
		return (
			<div className="share-buttons">
				<FacebookShareButton url={seoInfo.data.location} quote={seoInfo.data.seo_description}>
					<FacebookIcon size={32} round />
				</FacebookShareButton>
				<TwitterShareButton url={seoInfo.data.location} title={seoInfo.data.seo_title}>
					<TwitterIcon size={32} round />
				</TwitterShareButton>
				<LinkedinShareButton url={seoInfo.data.location} title={seoInfo.data.seo_title} summary={seoInfo.data.seo_description}>
					<LinkedinIcon size={32} round />
				</LinkedinShareButton>
				<EmailShareButton url={seoInfo.data.location} subject={seoInfo.data.seo_title} body={seoInfo.data.seo_description}>
					<EmailIcon size={32} round />
				</EmailShareButton>
			</div>
		);
	} else {
		return null
	}
};

export default ShareButtons;
