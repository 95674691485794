import React from 'react';
import ArticleCommunity from '../../components/articles/article_community';
import { useEffect } from 'react';
import axios from 'axios';
import { ourCommunitiesUrl } from '../../helpers/api_routes';
import { useState } from 'react';
import ErrorMsg from '../../components/error';

const OurCommunityShow = (props) => {
	const [items, setItems] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [errors, setErrors] = useState(null);

	useEffect(
		() => {
			getItems();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props]
	);

	const getItems = () => {
		axios
			.get(`${ourCommunitiesUrl.show}/${props.match.params.id}.json`)
			.then(function (response) {
				if (response.data.our_community.items) {
					setItems(response.data.our_community.items);
				}
				// backend should take care off pagination
			})
			.catch(function (error) {
				setErrors(error.toString());
			});
	};

	if (items) {
		return (
			<ArticleCommunity item={items} />
		);
	} else {
		if (errors) {
			return <ErrorMsg error={errors} />
		} else {
			return null;
		}
	}
};

export default OurCommunityShow;
