import React, { useEffect, useState } from 'react';
import { t } from '../../locales/translations';
import { contactUrl, pageRootUrl } from '../../helpers/api_routes';
import LazyLoad from 'react-lazyload';
import reactHtmlParser from 'react-html-parser';
import { profile } from '../../helpers/image_paths';

// for fetching data from server
const axios = require('axios');

function Team(props) {
	const [ team, setTeam ] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [ error, setError ] = useState('');

	useEffect(() => {
		getTeam();
	}, []);

	const getTeam = () => {
		// get team
		axios
			.get(contactUrl.team)
			.then(function(response) {
				props.setSectionsLoaded && props.setSectionsLoaded("team");
				setTeam(response.data.team_members.items);
			})
			.catch(function(error) {
				setError(t('sorry_error'));
			});
	};

	const makeContacts = (items) => {
		if (items) {
			return items.map((item, id) => {
				return (
					<div className={`card--team ${item.important && 'important'}`} key={item.id}>
						<div className="card__img">
							{item.image ? (
								<LazyLoad>
									<img src={`${pageRootUrl}/${item.image}`} alt={item.church_title} />
								</LazyLoad>
							) : (
								<img src={profile} alt={item.church_title} style={{opacity: 0.25}}/>
							)}
						</div>
						<div className="card__body">
							<div className="card__title">
								<span>{item.first_name}</span>
								<span> {item.last_name !== '-' && item.last_name}</span>
							</div>
							<div className="card__info"><strong>{reactHtmlParser(item.church_title)}</strong></div>
							<div className="card__description">
								{item.description}
								<a href={`mailto:${item.email}`} className="card__email">
									{item.email}
								</a>
								<div className={props.dark ? 'white' : 'black'}>{item.tel}</div>
							</div>
						</div>
					</div>
				);
			});
		} else {
			return <React.Fragment />;
		}
	};

	return (
		<section className={`section--padded--tb ${props.dark ? 'section--primary white' : ''}`}>
			<div className="layout--main">
				<div className="section__heading">
					<h1 className="">{t('team')}</h1>
				</div>
				<div className="grid--contacts grid--gap--small">{makeContacts(team)}</div>
			</div>
		</section>
	);
}

export default Team;
