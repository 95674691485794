import React from 'react';

// this component will decide to show its children or loader
// depending on the component calling it (paren)
// parent must have boolean awaitingServer key in state
class AwaitingServerWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			awaitingServer: this.props.awaitingServer
		};
	}

	static getDerivedStateFromProps(props, state) {
		return {
			awaitingServer: props.awaitingServer
		};
	}

	render() {
		if (this.state.awaitingServer) {
			return <React.Fragment />;
		} else {
			return this.props.children;
		}
	}
}

export default AwaitingServerWrapper;
